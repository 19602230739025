import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import css from "./index.module.css"
import ScansList from "../common/ScansList"
import SurfaceSelect from "../common/SurfaceSelect"
import Default from "./Default"
import { useGlobalState } from "../../state"
import { firestore as db } from "../../state/firebase"
import { Button, Layout, Radio, Select, Tooltip } from "antd"
import { generateDateTimeString } from "../../helpers/date"
import { ShareAltOutlined } from "@ant-design/icons"
import SharingModal from "./SharingModal"
import generateSurfacesSet from "../../helpers/generateSurfacesSet"
import { pushScanToHistory } from "../../helpers/url"
import ExternalImageViewer from "./ExternalImageViewer"

const ScansTab = ({ ...props }) => {
  const [state, dispatch] = useGlobalState()
  const [scans, setScans] = useState([])
  const [leftScan, setLeftScan] = useState(undefined)
  const [rightScan, setRightScan] = useState(undefined)
  const [surface, setSurface] = useState(undefined)
  const [surfaces, setSurfaces] = useState([])
  const [externalImageUrls, setExternalImageUrls] = useState([])
  const [externalToggleDisabled, setExteralToggleDisabled] = useState(false)
  const [loading, setLoading] = useState(true)
  const [compareScans, setCompareScans] = useState(false)
  const [viewExternal, setViewExternal] = useState(false)
  const [url, setUrl] = useState(new URL(window.location))
  const [history] = useState(useHistory())
  const [surfaceSelect, setSurfaceSelect] = useState(undefined)

  const { Option } = Select
  const { Content, Header } = Layout

  // Called when the user toggles between `single` and `compare` mode.
  // Parameters:
  //   `compare`: a Boolean that indicates whether we've switched to compare mode or not.
  const handleCompareFilterChange = (e) => {
    const compare = e.target.value === "true"
    const leftScanIndex = scans.indexOf(leftScan)

    if (compare === compareScans) return // Already in the right mode.
    // Determine whether we're entering compare mode for the first time, in
    // which case there is no `rightScan` set yet.
    if (compare) {
      // Move current scan to the right and show next-earliest scan on left.
      setLeftScan(scans[leftScanIndex + 1])
      setRightScan(scans[leftScanIndex])
    } else {
      // Keep right scan as the current scan in switch to single mode.
      setLeftScan(rightScan)
      setRightScan(undefined)
    }
    setCompareScans(compare)
  }

  // Called when the user changes scan date in single mode or first scan date in
  // compare mode.
  // Parameters:
  //   `leftScanId`: ID string of target scan (leftScan._id).
  const handleLeftDateFilterChange = (leftScanId) => {
    const newScan = scans.find((s) => s._id === leftScanId)
    setLeftScan(newScan)
  }

  // Called when the user changes second scan date in  compare mode.
  // Parameters:
  //   `rightScanId`: ID string of target scan (rightScan._id).
  const handleRightDateFilterChange = (rightScan) => {
    const newScan = scans.find((s) => s._id === rightScan)
    setRightScan(newScan)
    // Change left date if a right date selection causes it to be earlier
    // than the current left date.
    if (newScan.timestamp.seconds <= leftScan.timestamp.seconds) {
      const rightScanIndex = scans.indexOf(newScan)
      setLeftScan(scans[rightScanIndex + 1])
    }
  }

  // Called when the user changes surface e-value.
  // Parameters:
  //   `eValue`: e-value float of target surface.
  const handleSurfaceFilterChange = (eValue) => {
    setSurface(eValue)
  }

  const handleExternalToggle = (e) => {
    const externalView = e.target.value === "true"
    if (externalView === viewExternal) return
    setViewExternal(externalView)
  }

  // Show message there are not two scans to compare if user attempts to
  // switch to compare mode.
  const showDisabledTooltip =
    !compareScans &&
    (scans.length <= 1 || scans.indexOf(leftScan) === scans.length - 1)

  // Customize tooltip message based on reason button is disabled.
  const disabledTooltipMessage =
    scans.length === 1
      ? "A lens must have at least 2 scans to compare."
      : "No earlier scans to compare."

  const externalDisabledTooltipMessage = "No external images to view"

  useEffect(() => {
    if (leftScan === undefined) return
    db.getExternalImages(state.userOrgId, leftScan._id).then((imageUrls) => {
      setExternalImageUrls(imageUrls)
      const externalImagesUnavailable = imageUrls.length === 0
      setExteralToggleDisabled(externalImagesUnavailable)
      if (viewExternal && externalImagesUnavailable) setViewExternal(false)
    })
  }, [leftScan])

  const compareToggle = (
    <Radio.Group
      key="compareFilter"
      className={css.compareFilter}
      buttonStyle="solid"
      defaultValue={false}
      value={compareScans}
    >
      <Radio.Button
        value={true}
        onClick={handleCompareFilterChange}
        disabled={showDisabledTooltip}
      >
        <Tooltip
          placement="left"
          title={disabledTooltipMessage}
          overlayClassName={showDisabledTooltip ? "" : css.hidden}
        >
          Compare
        </Tooltip>
      </Radio.Button>
      <Radio.Button onClick={handleCompareFilterChange} value={false}>
        Single
      </Radio.Button>
    </Radio.Group>
  )

  const leftDateSelect = (
    <Select
      onChange={handleLeftDateFilterChange}
      placeholder="Scan Date"
      allowClear={false}
      className={css.dateFilter}
      dropdownMatchSelectWidth={false}
      key="dateFilterA"
      value={leftScan && leftScan._id}
    >
      {scans.map((scan) => {
        // Disable dates later than or equal to the right scan date so left date
        // is always earlier than the right.
        return compareScans &&
          scan.timestamp.seconds >= rightScan.timestamp.seconds ? (
          <Option
            key={scan._id}
            value={scan._id}
            className={css.inventoryOption}
            disabled
          >
            {" "}
            <Tooltip
              placement="left"
              title="Select an older scan to compare against right scan."
            >
              {generateDateTimeString(scan.timestamp.seconds)}
            </Tooltip>
          </Option>
        ) : (
          <Option
            key={scan._id}
            value={scan._id}
            className={css.inventoryOption}
          >
            {generateDateTimeString(scan.timestamp.seconds)}
          </Option>
        )
      })}
    </Select>
  )

  const rightDateSelect = (
    <Select
      onChange={handleRightDateFilterChange}
      placeholder="Scan Date"
      allowClear={false}
      className={css.dateFilter}
      dropdownMatchSelectWidth={false}
      key="dateFilterB"
      value={rightScan && rightScan._id}
    >
      {scans.map((scan, i) => {
        // Disable earliest option so right scan date is always later than left.
        return i === scans.length - 1 ? (
          <Option
            key={scan._id}
            value={scan._id}
            className={css.inventoryOption}
            disabled
          >
            <Tooltip
              placement="left"
              title="Select a newer scan to compare against left scan."
            >
              {generateDateTimeString(scan.timestamp.seconds)}
            </Tooltip>
          </Option>
        ) : (
          <Option
            key={scan._id}
            value={scan._id}
            className={css.inventoryOption}
          >
            {generateDateTimeString(scan.timestamp.seconds)}
          </Option>
        )
      })}
    </Select>
  )

  const generateSurfaceSelect = (surface, surfaces) => {
    return (
      surface &&
      surfaces.length > 0 && (
        <SurfaceSelect
          surface={surface}
          surfaces={surfaces}
          handleSurfaceFilterChange={handleSurfaceFilterChange}
          disabled={viewExternal}
        />
      )
    )
  }

  // Button toggle to switch between internal and external views (if available).
  const externalToggle = (
    <Radio.Group
      key="externalToggle"
      className={css.compareFilter}
      buttonStyle="solid"
      defaultValue={false}
      value={viewExternal}
    >
      <Radio.Button
        value={true}
        onClick={handleExternalToggle}
        disabled={externalToggleDisabled}
      >
        <Tooltip
          placement="left"
          title={externalDisabledTooltipMessage}
          overlayClassName={externalToggleDisabled ? "" : css.hidden}
        >
          External
        </Tooltip>
      </Radio.Button>
      <Radio.Button onClick={handleExternalToggle} value={false}>
        Internal
      </Radio.Button>
    </Radio.Group>
  )

  const filters = compareScans
    ? [
        externalToggle,
        compareToggle,
        leftDateSelect,
        rightDateSelect,
        surfaceSelect,
      ]
    : [externalToggle, compareToggle, leftDateSelect, surfaceSelect]

  // Called to find scan inside scans array by timestamp.
  // Parameters:
  //   `seconds`: timestamp.seconds as a string from query parameters.
  const findScanByTimestamp = (seconds, scansArray) => {
    return scansArray.find(
      (scan) => scan.timestamp.seconds === parseInt(seconds)
    )
  }

  // Register browser history listener.
  useEffect(() => {
    history.listen(() => {
      setUrl(new URL(window.location))
    })
  }, [])

  // Listen to URL path changes.
  useEffect(() => {
    const params = new URLSearchParams(url.search)
    if (params.get("lens") && state.userOrgId !== undefined) {
      db.getLens(state.userOrgId, params.get("lens")).then((doc) => {
        dispatch({ type: "SET_INVENTORY_LENS", lens: doc })
      })
    }
    // TODO: handle invalid search params
  }, [url.pathname, state.userOrgId])

  // Clean up upon navigation away from page.
  useEffect(() => {
    const resetInventoryLens = () => {
      dispatch({ type: "SET_INVENTORY_LENS", lens: undefined })
    }
    return () => {
      setLeftScan(undefined)
      setRightScan(undefined)
      resetInventoryLens()
    }
  }, [])

  // Listen to lens selection and set lens.
  useEffect(() => {
    if (state.inventoryLens !== undefined) {
      setLeftScan(undefined)
      setRightScan(undefined)
      setSurface(undefined) // Reset selected surface upon lens change.
      setCompareScans(false) // Set/reset to single mode upon lens selection.
      db.getScansByLens(state.userOrgId, state.inventoryLens.lens_id).then(
        (scans) => {
          setScans(scans)
          setLoading(false)
        }
      )
    }
  }, [state.inventoryLens, state.userOrgId])

  const showShareModal = () => {
    dispatch({ type: "SET_SHARE_MODAL_VISIBLE", visible: true })
  }

  // Parse optional URL query parameters.
  useEffect(() => {
    const searchParams = url.searchParams
    const leftDateQuery = searchParams.get("date_a")
    const rightDateQuery = searchParams.get("date_b")
    const surfaceQuery = searchParams.get("surface")
    let internalLeftScan = scans[0]
    let internalRightScan = undefined

    if (scans.length > 0) {
      if (leftDateQuery !== null) {
        // Find leftScan by given "date_a" query parameter.
        const leftScanByDate = findScanByTimestamp(leftDateQuery, scans)
        internalLeftScan = leftScanByDate || internalLeftScan

        if (rightDateQuery !== null) {
          // Find rightScan by given "date_b" query parameter.
          const rightScanByDate = findScanByTimestamp(rightDateQuery, scans)
          internalRightScan = rightScanByDate
        }
      }
      // Set surface if "surface" is a query parameter and given surface is a valid name.
      if (
        surfaceQuery !== null &&
        Object.keys(internalLeftScan.surfaces).includes(surfaceQuery)
      ) {
        setSurface(surfaceQuery)
      } else {
        setSurface(surfaces[0])
      }
      setLeftScan(internalLeftScan)
      setRightScan(internalRightScan)
      // Enable compare mode if left and right scans are given and both exist.
      setCompareScans(
        internalLeftScan !== undefined && internalRightScan !== undefined
      )
    }
  }, [scans])

  // Create a Set of all surface names present in leftScan and rightScan.
  useEffect(() => {
    const allSurfaces = generateSurfacesSet(leftScan, rightScan)
    if (allSurfaces.length === 0) return
    setSurfaces(allSurfaces)
    // Persist surface upon scan change if it exists in new set of surfaces.
    if (allSurfaces.includes(surface)) {
      setSurfaceSelect(generateSurfaceSelect(surface, allSurfaces))
    } else {
      setSurface(allSurfaces[0])
      setSurfaceSelect(generateSurfaceSelect(allSurfaces[0], allSurfaces))
    }
  }, [leftScan, rightScan])

  // Generate list of surfaces for selection.
  useEffect(() => {
    surface &&
      surfaces.length > 0 &&
      setSurfaceSelect(generateSurfaceSelect(surface, surfaces))
  }, [surface, surfaces, viewExternal])

  // Update URL to include surface, left scan ID and right scan ID.
  useEffect(() => {
    pushScanToHistory(leftScan, surface, rightScan)
  }, [leftScan, rightScan, surface])

  useEffect(() => {
    const loaded = state.userOrgId !== undefined && leftScan

    loaded !== state.loaded && dispatch({ type: "SET_LOADED", loaded: loaded })
  }, [state.userOrgId, leftScan])

  return (
    <div>
      <Layout className={css.inventoryContainer}>
        <Layout style={{ borderRadius: "0 6px 6px 0" }} id="tour-step-1">
          <div
            className={state.inventoryLens ? css.headerContainer : css.hidden}
            id="filtersHeader"
          >
            <Header className={css.filtersContainer} id="tour-step-2">
              {filters.map((filter, i) => (
                <div className={css.filterContainer} key={i}>
                  {filter}
                </div>
              ))}
              <div className={css.shareButtonContainer}>
                <Button
                  type="primary"
                  disabled={leftScan === undefined}
                  onClick={showShareModal}
                  ghost
                >
                  <ShareAltOutlined />
                </Button>
              </div>
            </Header>
          </div>

          {leftScan && state.inventoryLens !== undefined && (
            <SharingModal
              leftScan={leftScan}
              rightScan={rightScan}
              surface={surface}
              compareScans={compareScans}
              url={url}
              user={props.user}
              serialNumber={state.inventoryLens.serial_number}
            />
          )}

          <Content>
            {viewExternal ? (
              <ExternalImageViewer imageUrls={externalImageUrls} />
            ) : (
              <div className={css.scansListContainer}>
                {state.inventoryLens !== undefined ? (
                  <ScansList
                    leftScan={leftScan}
                    rightScan={rightScan}
                    surface={surface}
                    compareScans={compareScans}
                    noScans={scans.length === 0 && !loading}
                  />
                ) : (
                  <Default />
                )}
              </div>
            )}
          </Content>
        </Layout>
      </Layout>
    </div>
  )
}

ScansTab.propTypes = {
  user: PropTypes.object,
}

export default ScansTab
