import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Card, Checkbox, List } from "antd"
import { useGlobalState } from "../../state"
import css from "./LensCard.module.css"
import { FullscreenOutlined } from "@ant-design/icons"
import LensModal from "../common/LensModal"
import LensTitle from "../common/LensTitle"
import { pushLensToHistory, resetHistory } from "../../helpers/url"

const LensCard = ({
  lens,
  selectable,
  batchSelected,
  modifyBatch,
  listView,
  scanWizard,
}) => {
  const [state, dispatch] = useGlobalState()
  const [modalVisible, setModalVisible] = useState(false)

  const cardHead = {
    padding: "0px 16px",
    margin: 0,
    borderBottom: "none",
  }

  const cardBody = {
    height: "0px",
  }

  const handleCardClick = () => {
    if (scanWizard) {
      dispatch({ type: "SET_CURRENT_SCAN_LENS", lens: lens })
    } else {
      dispatch({
        type: "SET_INVENTORY_LENS",
        lens: lens,
      })
      pushLensToHistory(lens)
      setModalVisible(true)
    }
  }

  const handleCancel = () => {
    resetHistory()
    setModalVisible(false)
    dispatch({
      type: "SET_INVENTORY_LENS",
      lens: null,
    })
  }

  // Parse url search parameters if present.
  useEffect(() => {
    if (!lens) return
    const params = new URLSearchParams(window.location.search)
    if (params.get("lens") === lens.lens_id) {
      dispatch({
        type: "SET_INVENTORY_LENS",
        lens: lens,
      })
      setModalVisible(true)
    }
  }, [lens])

  return (
    <div>
      {state.inventoryLens !== null && (
        <LensModal visible={modalVisible} onCancel={handleCancel} lens={lens} />
      )}
      {/* Keep checkbox outside of card so checkbox hover is separate from card hover.*/}
      {selectable && (
        <Checkbox
          checked={batchSelected}
          onChange={modifyBatch}
          value={lens.lens_id}
          className={css.checkBox}
        />
      )}
      {listView ? (
        <List.Item
          className={cx("listItem", {
            [css.batchSelected]: batchSelected,
            [css.selected]:
              state.currentScanLens !== null &&
              state.currentScanLens.lens_id === lens.lens_id,
          })}
        >
          <List.Item.Meta
            onClick={handleCardClick}
            title={<LensTitle lens={lens} selectable={selectable} listView />}
            id={`target_${lens.lens_id}`}
          />
        </List.Item>
      ) : (
        <Card
          title={<LensTitle lens={lens} selectable={selectable} />}
          className={cx("card", "lensCard", {
            [css.batchSelected]: batchSelected,
            [css.selected]:
              state.currentScanLens !== null &&
              state.currentScanLens.lens_id === lens.lens_id,
          })}
          id={`target_${lens.lens_id}`}
          headStyle={cardHead}
          bodyStyle={cardBody}
          onClick={handleCardClick}
          hoverable
        >
          <FullscreenOutlined className={css.pullLeft} />
        </Card>
      )}
    </div>
  )
}

export default LensCard

LensCard.propTypes = {
  lens: PropTypes.object,
  selectable: PropTypes.bool,
  batchSelected: PropTypes.bool,
  modifyBatch: PropTypes.func,
  listView: PropTypes.bool,
  scanWizard: PropTypes.bool,
}
