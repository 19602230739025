import React, { useEffect, useState } from "react"
import settingsCss from "./index.module.css"
import PropTypes from "prop-types"
import { firestore as db } from "../../state/firebase"
import {
  Button,
  Input,
  Typography,
  Form,
  Divider,
  Table,
  Popconfirm,
  Tooltip,
} from "antd"
import { EditOutlined } from "@ant-design/icons"
import { useGlobalState } from "../../state"

const { Title } = Typography

const UserManagementTable = () => {
  const [state] = useGlobalState()
  const [invites, setInvites] = useState([])
  const [orgUsers, setOrgUsers] = useState([])
  const [dataSource, setDataSource] = useState([])
  const [newOrgUserForm] = Form.useForm()

  // Button, function for resending email invite.
  const resendInvite = (record) => {
    db.resendEmailInvite(record._id, state.userOrgId)
  }
  const inviteAction = (_text, record) => (
    <Button type="link" onClick={() => resendInvite(record)}>
      Resend Invite
    </Button>
  )

  // Button, function for removing user from org.
  // DO NOT REMOVE MORE THAN ONE USER AT A TIME. Our backend isn't currently
  // built to support that.
  const deleteUser = (record) => {
    db.deleteUser(state.userOrgId, record.id)
  }
  const defaultAction = (text, record) =>
    // Don't allow user to delete themself (an owner).
    text.id === state.uid ? (
      <Tooltip title="Can't delete self." placement="right">
        <Button type="link" disabled>
          Revoke access
        </Button>
      </Tooltip>
    ) : (
      <Popconfirm
        title={`Are you sure you want to remove ${
          record.displayName || record.email
        }?`}
        onConfirm={() => deleteUser(record)}
        onCancel={null}
      >
        <Button type="link">Revoke access</Button>
      </Popconfirm>
    )

  // Button, function for inviting new organization user.
  const addNewOrgUser = async (form) => {
    const newEmail = form.newOrgUserEmail
    db.inviteNewOrgUser(state.userOrgId, newEmail).then(() => {
      newOrgUserForm.resetFields()
    })
  }

  // Form for inviting new organization user from the last data row of the
  // "Manage Users" table.
  const inviteUserRow = {
    identifier: (
      <Form form={newOrgUserForm} onFinish={addNewOrgUser}>
        <Form.Item name="newOrgUserEmail">
          <Input type="email" placeholder="E-mail of new user" />
        </Form.Item>
      </Form>
    ),
    action: (text, record) => inviteUserAction(text, record),
    key: "inviteUserRow",
  }

  // Button for submitting new invite form.
  const inviteUserAction = (_text, _record) => {
    return (
      <Form form={newOrgUserForm} onFinish={addNewOrgUser}>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Invite
          </Button>
        </Form.Item>
      </Form>
    )
  }

  // Add a user invite row to the bottom of the "Manage Users" table.
  const addInviteUserRowToDataSource = () => {
    const dataSourceCopy = [
      ...new Set([...orgUsers, ...invites, inviteUserRow, addUserButton]),
    ]
    setDataSource(dataSourceCopy)
  }

  // Persistent button in the bottom row of the table that dynamically adds a
  // row with a form to invite a new email-verified user.
  const addUserButton = {
    identifier: (
      <Button onClick={addInviteUserRowToDataSource} type="primary">
        Add User
      </Button>
    ),
    fullRow: true,
    action: () => {},
    key: "addUserButton",
  }

  // Handler for users listener. Turn invites into table row entries.
  const onModify = (orgUsers) => {
    orgUsers = orgUsers.map((orgUser) => {
      const owner =
        orgUser.owned_organizations !== undefined &&
        orgUser.owned_organizations.includes(state.userOrgId)
      return {
        ...orgUser,
        identifier: orgUser.displayName || orgUser.email,
        action: (text, record) => defaultAction(text, record),
        role: owner ? "Owner" : "User",
        key: orgUser.id,
      }
    })
    setOrgUsers(orgUsers)
  }

  // Handler for invites listener. Turn invites into table row entries.
  const onDone = (invites) => {
    invites.forEach((invite, i) => {
      invites[i] = {
        ...invite,
        identifier: invite.email,
        action: (text, record) => inviteAction(text, record),
        role: "Invitee",
        key: invite._id,
      }
    })
    setInvites(invites)
  }

  // Listen for changes in current organization users and unsubscribe when
  // organization changes.
  useEffect(() => {
    if (state.userOrgId === undefined) return
    const unsubscribeUsersPromise = db.onOrgUsers(state.userOrgId, onModify)
    return () => {
      unsubscribeUsersPromise.then((unsubscribeFunction) => {
        unsubscribeFunction()
      })
    }
  }, [state.userOrgId])

  // Listen for changes in current organization invites and unsubscribe when
  // organization changes.
  useEffect(() => {
    if (state.userOrgId === undefined) return
    const unsubscribeInvitesPromise = db.onOrgInvites(state.userOrgId, onDone)
    return () => {
      unsubscribeInvitesPromise.then((unsubscribeFunction) => {
        unsubscribeFunction()
      })
    }
  }, [state.userOrgId])

  // Update table data source upon changes in users and invites.
  useEffect(() => {
    setDataSource([...orgUsers, ...invites, addUserButton])
  }, [orgUsers, invites])

  const columns = [
    {
      title: "User",
      dataIndex: "identifier",
      render: (_text, record) => {
        if (record.fullRow === true) {
          return {
            children: record.identifier,
            props: {
              colSpan: 3,
            },
          }
        }
        return record.identifier
      },
    },
    {
      title: "Role",
      key: "role",
      render: (_text, record) => {
        return record.role
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return record.action(text, record)
      },
    },
  ]
  return (
    <Table columns={columns} dataSource={dataSource} pagination={false}></Table>
  )
}

const OrgDetails = ({ orgsData }) => {
  const [state] = useGlobalState()
  const [orgNameForm] = Form.useForm()
  const [editMode, setEditMode] = useState(false)
  const [orgData, setOrgData] = useState({})

  const toggleEditMode = () => {
    setEditMode(!editMode)
  }

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  }

  // Fill in org details and allow user to set org name if it is blank.
  useEffect(() => {
    if (orgsData.length === 0) return
    const org = orgsData.find((org) => org._id === state.userOrgId)
    setOrgData(org)
    setEditMode(org.name === "")
  }, [state.userOrgId, orgsData])

  // Update Organization name.
  const onOrgNameSet = () => {
    const formValues = orgNameForm.getFieldsValue()
    db.updateOrgName(state.userOrgId, formValues.name).then(() => {
      toggleEditMode()
      setOrgData({ ...orgData, name: formValues.name })
    })
  }

  return (
    <div style={{ width: "100%" }}>
      <Title level={4} className={settingsCss.title}>
        Organization{" "}
        {!editMode && (
          <Button
            icon={<EditOutlined />}
            onClick={toggleEditMode}
            className={settingsCss.topLeft}
            disabled
          ></Button>
        )}
      </Title>
      <Form
        {...layout}
        initialValues={orgData}
        name="orgName"
        onFinish={onOrgNameSet}
        form={orgNameForm}
        style={{ width: "100%" }}
      >
        <Form.Item label="Name" name="name">
          {editMode ? <Input /> : orgData.name}
        </Form.Item>
        {editMode && (
          <Form.Item>
            <Button type="primary" htmlType="submit" ghost block>
              Save
            </Button>
          </Form.Item>
        )}
      </Form>
      <Divider>Manage Users</Divider>
      <UserManagementTable />
    </div>
  )
}

OrgDetails.propTypes = {
  orgsData: PropTypes.array,
}

export default OrgDetails
