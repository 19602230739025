import { COLLECTION } from "../constants"
import {
  getDb,
  aliasLenses,
  describeLensModel,
  sortLenses,
  associateLensTypeswithLenses,
} from "./helpers"

const { LENS_TYPES, ORGANIZATIONS, LENSES } = COLLECTION

const lensTypes = {
  // Get all LensTypes then all Lens instances of each type for given org.
  getLensTypes: async (orgId) => {
    const db = await getDb()
    const snapshot = await db
      .collection(LENS_TYPES)
      .where("make", ">", "")
      .get()
    let data = {}
    snapshot.docs.forEach((doc) => {
      let doc_data = doc.data()
      doc_data = describeLensModel(doc_data)
      if (doc_data.type_id === undefined) doc_data.type_id = doc.id
      data[doc.id] = doc_data
    })
    let lensInstances = await db
      .collection(ORGANIZATIONS)
      .doc(orgId)
      .collection(LENSES)
      .get()

    lensInstances = lensInstances.docs.map((doc) => {
      return { lens_id: doc.id, ...doc.data() }
    })
    lensInstances = lensInstances.filter(
      (lens) =>
        lens.lens_type_ref !== undefined &&
        lens.serial_number !== null &&
        lens.serial_number !== undefined
    )
    data = associateLensTypeswithLenses(data, lensInstances)

    data = Object.values(data)
    data = aliasLenses(data)
    // Sort by Make, then Model, then Focal Length, then Image Diameter, then T-Stop.
    return sortLenses(data)
  },
  addLensType: async (lens) => {
    const db = await getDb()
    let ref = undefined
    await db
      .collection(COLLECTION.LENS_TYPES)
      .add({ ...lens, calibrated_front_rear: null })
      .then(async (doc) => {
        ref = doc
        return await doc.update({ type_id: doc.id })
      })
    return await ref.get().then((doc) => doc.data())
  },
  // Update missing details of a a LensType and return its data.
  updateLensType: async (tracisId, lens) => {
    const db = await getDb()
    const lensTypeRef = db.collection(LENS_TYPES).doc(tracisId)
    await lensTypeRef.update({
      focal_length_mm: lens.focal_length_mm,
      image_diameter: lens.image_diameter,
      length: lens.length,
      tstop: lens.tstop,
      mount: lens.mount,
    })
    return await lensTypeRef.get().then((doc) => {
      return { ...doc.data(), type_id: tracisId }
    })
  },
  // Get a LensType's details.
  getLensType: async (typeId) => {
    const db = await getDb()
    const lensTypeDoc = await db.collection(LENS_TYPES).doc(typeId).get()
    return { ...lensTypeDoc.data(), type_id: typeId }
  },
}

export default lensTypes
