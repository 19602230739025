import React, { useRef, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Modal, Button, Radio } from "antd"
import { useReactToPrint } from "react-to-print"
import tracisLogoWhite from "../../images/tracis_horiz_white_sm.png"
import PrintableLenses from "./PrintableLenses"
import { useGlobalState } from "../../state"
import scansApi from "../../state/firebase/scans"
import scratchesApi from "../../state/firebase/scratches"
import notesApi from "../../state/firebase/notes"
import organizationsApi from "../../state/firebase/organizations"
import { describeLensModel } from "../../state/firebase/helpers"
import css from "./CombinedLensModal.module.css"
import "./CombinedLensModal.css"

export const MODE = {
  SCANS: "scans",
  SCRATCHES: "scratches",
}

export default function CombinedLensCard(props) {
  const [state] = useGlobalState()
  const [mode, setMode] = useState(MODE.SCANS)
  const [scans, setScans] = useState([])
  const [scratches, setScratches] = useState([])
  const [customLogoPath, setCustomLogoPath] = useState()
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `tracis_report_${new Date().toISOString().split("T")[0]}`,
  })

  const fetchData = () => {
    const notesPromises = [...props.lensIds].map((lensId) =>
      notesApi.getNotes(state.userOrgId, lensId)
    )

    const scansPromises = [...props.lensIds].map((lensId) =>
      scansApi.getRecentScanByLens(state.userOrgId, lensId)
    )

    const scratchPromises = [...props.lensIds].map((lensId) =>
      scratchesApi.getScratches(state.userOrgId, lensId)
    )

    Promise.all([
      Promise.all(notesPromises),
      Promise.all(scansPromises),
      Promise.all(scratchPromises),
    ]).then(([notes, scans, scratches]) => {
      // Magic incantation to flatten array of arrays, compatible with IE 11.
      const flattenedPublicNotes = [].concat
        .apply([], notes)
        .filter((note) => note.showOnPrintout)

      const lensScans = scans.map((scan) => ({
        ...scan,
        name: `${scan.lensType.make} ${scan.lensType.model}`,
        frontImages:
          scan.surfaces && "front (1000)" in scan.surfaces
            ? [scan.surfaces["front (1000)"].image_url]
            : [],
        rearImages:
          scan.surfaces && "rear (0000)" in scan.surfaces
            ? [scan.surfaces["rear (0000)"].image_url]
            : [],
        focalLength: describeLensModel(scan.lensType).focal_length_description,
        serialNumber: scan.lens.serial_number,
        notes: flattenedPublicNotes.filter(
          (note) => note.lens.id === scan.lens.id
        ),
      }))
      setScans(lensScans)
      const lensScratches = scratches.map((scratch) => ({
        ...scratch,
        name: `${scratch.lensType.make} ${scratch.lensType.model}`,
        frontImages: scratch.frontImages,
        rearImages: scratch.rearImages,
        focalLength: describeLensModel(scratch.lensType)
          .focal_length_description,
        serialNumber: scratch.lens.serial_number,
        notes: flattenedPublicNotes.filter(
          (note) => note.lens.id === scratch.lens.id
        ),
      }))

      setScratches(lensScratches)
    })
  }

  // Refetch data if window closed in case anything changed in the meantime.
  useEffect(() => {
    if (props.visible) {
      fetchData()
    }
  }, [props.visible])

  useEffect(() => {
    organizationsApi.getOrgDetails([state.userOrgId]).then((data) => {
      setCustomLogoPath(data[0].customLogoPath)
    })
  }, [state.userOrgId])

  return (
    <Modal
      centered
      maskStyle={{ backdropFilter: "blur(8px)" }}
      footer={null}
      width="96vw"
      bodyStyle={{
        padding: 0,
        overflowY: "scroll",
        overflowX: "hidden",
        border: "1px solid #34383B",
        height: `${window.innerHeight * 0.96}px`,
      }}
      {...props}
    >
      <header className={css.header}>
        <div className={css.headerContainer}>
          <img
            className={css.logo}
            src={tracisLogoWhite}
            alt="Tracis logo white"
          />
          <div>
            <Radio.Group
              key="compareFilter"
              className={css.compareFilter}
              buttonStyle="solid"
              defaultValue={false}
              value={mode}
              size="large"
            >
              <Radio.Button
                value={MODE.SCRATCHES}
                onClick={() => setMode(MODE.SCRATCHES)}
              >
                Scratches
              </Radio.Button>
              <Radio.Button
                onClick={() => setMode(MODE.SCANS)}
                value={MODE.SCANS}
              >
                Scans
              </Radio.Button>
            </Radio.Group>
            <Button size="large" onClick={handlePrint}>
              Print
            </Button>
          </div>
        </div>
      </header>
      <div className={css.page}>
        <div className={css.printableCardContainer}>
          <PrintableLenses
            ref={componentRef}
            scans={scans}
            scratches={scratches}
            mode={mode}
            customLogoPath={customLogoPath}
          />
        </div>
      </div>
    </Modal>
  )
}

CombinedLensCard.propTypes = {
  lensIds: PropTypes.object,
  visible: PropTypes.bool,
}
