import React from "react"
import PropTypes from "prop-types"
import { Typography } from "antd"
import { Link } from "react-router-dom"
import { STEPS } from "../../state/constants"

const { Title, Paragraph } = Typography

const SandboxScan = ({ setStep }) => {
  const resetWizard = () => {
    setStep(STEPS.SELECT_SCANNER)
  }

  return (
    <div>
      <Title level={5}>Scanning</Title>
      <Paragraph>
        When you own a scanner, this is where you see a scan in progress.
      </Paragraph>
      <Paragraph>
        Check out some scan results in{" "}
        <Link to="/scan_history" onClick={resetWizard}>
          Scan History
        </Link>
        .
      </Paragraph>
    </div>
  )
}

SandboxScan.propTypes = {
  setStep: PropTypes.func,
}

export default SandboxScan
