import React, { useEffect } from "react"
import PropTypes from "prop-types"
import withFirebaseAuth from "react-with-firebase-auth"
import { providers, getAuth } from "../../../state/firebase/helpers"
import { Helmet } from "react-helmet"
import { Layout } from "antd"
import { Switch, Route, BrowserRouter as Router } from "react-router-dom"
import { StateProvider, useGlobalState } from "../../../state"
import { reducer } from "../../../state/reducer"
import { defaultState } from "../../../state/defaultState"
import AppLayout from "../AppLayout"
import Loading from "../../common/Loading"
import "./index.css"
import SharedLinkViewer from "../../SharedLinkViewer"
import { UnSignedInApp } from "./UnSignedInApp"
import { firestore as db } from "../../../state/firebase"
import { captureException } from "@sentry/react"

const SignedInApp = ({ signOutAndRedirect, user, invitedUser, ...props }) => {
  const [, dispatch] = useGlobalState()
  useEffect(() => {
    let unsubscribe
    if (!user) return
    unsubscribe = db.onOrgsForUser(user.uid, (organizations) => {
      dispatch({ type: "SET_USER_ORGS_IDS", userOrgIds: organizations })
      dispatch({ type: "SET_USER_ORG_ID", userOrgId: organizations[0] })
    })
    return () => {
      !user && unsubscribe()
    }
  }, [user.uid])

  return (
    <AppLayout
      user={user}
      signOutAndRedirect={signOutAndRedirect}
      invitedUser={invitedUser}
      {...props}
    />
  )
}

SignedInApp.propTypes = {
  signOutAndRedirect: PropTypes.func,
  user: PropTypes.object,
  invitedUser: PropTypes.object,
}

// Guest version of application with single route (/shared/<sharingLinkId>)
// that does not require Firebase Auth.
const GuestApp = (props) => {
  const { Content } = Layout
  return (
    <StateProvider initialState={defaultState} reducer={reducer}>
      <Router>
        <Layout>
          <Content className="fadeIn">
            <Switch>
              <Route path="/shared/:sharingLinkId">
                <SharedLinkViewer {...props} guest />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Router>
    </StateProvider>
  )
}

function App({ user, ...props }) {
  const [, dispatch] = useGlobalState()

  const signOutAndRedirectAndRedirect = async () => {
    const auth = await getAuth()
    auth.signOut()
    window.location = "signin"
  }

  // Handle sign in with email link.
  useEffect(() => {
    const emailSignIn = async () => {
      const auth = await getAuth()

      // Either say "congrats you're in X org" if already a user, otherwise
      if (auth.isSignInWithEmailLink(window.location.href)) {
        dispatch({ type: "SET_INVITED_USER", invtedUser: true })
        auth
          .signOut() // Sign out if another user is currently signed in.
          .then(async () => {
            // Get Invite document with ID in search params.
            let email
            const params = new URLSearchParams(window.location.search)
            const inviteId = params.get("token")
            const orgId = params.get("orgId")
            const inviteData = await db.getInviteByID(inviteId, orgId)
            email = inviteData.email
            if (inviteData === undefined) {
              window.alert(
                `Please contact your organization owner for an invitation.`
              )
              return
            }
            auth
              .signInWithEmailLink(email, window.location.href)
              .catch(function (error) {
                if (error.code === "auth/invalid-action-code") {
                  window.alert(
                    "Link is expired or has already been used. Please contact your organization owner for a new one if this is an error."
                  )
                }
                console.error(error)
                captureException(error)
                return
              })
          })
      }
    }
    emailSignIn() // Function definition then call is necessary for async inside of useEffect.
  }, [])

  return (
    <div className="App">
      <Router>
        <Helmet>
          <style>{"body { background-color: #25292C; }"}</style>
        </Helmet>
        {user ? (
          <SignedInApp
            user={user}
            signOutAndRedirect={signOutAndRedirectAndRedirect}
            {...props}
          />
        ) : user === undefined ? (
          <Loading empty />
        ) : window.location.pathname.includes("shared") ? (
          <GuestApp {...props} />
        ) : (
          <UnSignedInApp {...props} />
        )}
      </Router>
    </div>
  )
}

App.propTypes = {
  user: PropTypes.object,
}

async function appWithAuth() {
  return withFirebaseAuth({
    providers,
    firebaseAppAuth: await getAuth(),
  })(App)
}

export default appWithAuth()
