import React from "react"
import PropTypes from "prop-types"
import css from "./index.module.css"
import { Spin } from "antd"

const Loading = ({ title, empty }) => {
  return (
    <div className={css.container}>
      <div className={css.loading}>
        <Spin
          className={css.spin}
          size="large"
          tip={empty ? "" : `Loading ${title}`}
        />
      </div>
    </div>
  )
}

Loading.propTypes = {
  title: PropTypes.string,
  empty: PropTypes.bool,
}

export default Loading
