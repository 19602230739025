import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Button, Row, Col, Affix, PageHeader, Input, Switch } from "antd"
import { firestore as db } from "../../state/firebase"
import { useGlobalState } from "../../state"
import css from "./index.module.css"
import LensCard from "./LensCard"
import { generateTrie, findLenses } from "../../helpers/search"
import useDebounce from "../../helpers/useDebounce"
import Loading from "../common/Loading"
import { SEARCH_PLACEHOLDER } from "../../state/constants"
import AddLensCard from "./AddLensCard"
import { sortLenses } from "../../state/firebase/helpers"
import {
  AppstoreOutlined,
  BarsOutlined,
  PrinterOutlined,
} from "@ant-design/icons"
import CombinedLensModal from "./CombinedLensModal"
import HiddenTooltip from "../common/helpers/HiddenTooltip"

const Inventory = ({ handleContinue, scanWizardTree = false }) => {
  const [state, dispatch] = useGlobalState()
  const [lenses, setLenses] = useState([])
  const [filteredLenses, setFilteredLenses] = useState([])
  const [trie, setTrie] = useState(undefined)
  const [searchInput, setSearchInput] = useState("")
  const [isSearching, setIsSearching] = useState(true)
  const [lensesFetched, setLensesFetched] = useState(false)
  const debouncedSearchInput = useDebounce(searchInput, 300)
  const [batch, setBatch] = useState(new Set())
  const [defaultOpen] = useState(null)
  const [listView, setListView] = useState(scanWizardTree)
  // lensesIdentifier is a surrogate the lenses array because the useEffect
  // hook doesn't reliably respond to changes in large arrays of complex
  // objects and we need to monitor lenses being loaded and added.
  const [lensesIdentifier, setLensesIdentifier] = useState("")

  const [showCombinedModal, setShowCombinedModal] = useState(false)
  let inventorySearchInput = useRef()

  // Open lens modal upon add.
  useEffect(() => {
    if (defaultOpen === null) return
    const element = document.getElementById(`target_${defaultOpen}`)
    if (element !== null) element.click()
  }, [document.getElementById(`target_${defaultOpen}`)])

  // Sorting lenses and generating the trie are computationally expensive
  // operations, so we only do it upon initial load and upon new lenses
  // being added.
  useEffect(() => {
    if (!lensesFetched) return
    let lensesCopy = sortLenses(lenses)
    const ts = generateTrie(lensesCopy)
    setLenses(lensesCopy)
    setTrie(ts)
  }, [lensesIdentifier, lensesFetched])

  // Filter lenses by search input and live-update filtered list
  // upon new lens addition.
  useEffect(() => {
    if (debouncedSearchInput !== "") {
      findLenses(trie, debouncedSearchInput).then((filtered) => {
        setFilteredLenses(filtered)
        setIsSearching(false)
      })
      return
    }
    setFilteredLenses(lenses)
  }, [trie, debouncedSearchInput])

  // Called upon initial fetch of all lenses and upon lens add.
  const onDone = (lensDocs, counter) => {
    setLenses(lensDocs)
    setLensesIdentifier(lensDocs.map((l) => l.lens_id).join("_"))
    setIsSearching(false)
    setLensesFetched(true)
    if (counter === 1 && !scanWizardTree) {
      setSearchInput("")
    }
  }

  useEffect(() => {
    let unsubscribe
    if (state.userOrgId === undefined) return
    if (!lensesFetched) {
      unsubscribe = db.onLensesChange(state.userOrgId, onDone)
    }
    return () => {
      // Clean up upon component unmount to avoid memory leaks by detaching Firestore
      // onLensesChange listener. This syntax is how you implement componentWillUnmount
      // inside a functional component using hooks.
      setLenses([])
      lensesFetched && unsubscribe()
    }
  }, [state.userOrgId])

  // Add or remove lenses from batch upon checkbox check or uncheck.
  const modifyBatch = (e) => {
    const value = e.target.value
    const checked = e.target.checked
    let batchCopy = new Set(JSON.parse(JSON.stringify([...batch])))
    checked ? batchCopy.add(value) : batchCopy.delete(value)
    setBatch(batchCopy)
  }

  // Handle search input with useDebounce.
  useEffect(
    () => {
      if (debouncedSearchInput !== "") {
        setIsSearching(true)
        findLenses(trie, debouncedSearchInput).then((filtered) => {
          setFilteredLenses(filtered)
          setIsSearching(false)
          // Auto-select lens and continue in scan wizard if a barcode has been
          // entered and there is only one match.
          if (
            scanWizardTree &&
            filtered.length === 1 &&
            filtered[0].barcode !== undefined &&
            filtered[0].barcode !== null &&
            debouncedSearchInput.toLowerCase() ===
              filtered[0].barcode.toLowerCase()
          ) {
            dispatch({ type: "SET_CURRENT_SCAN_LENS", lens: filtered[0] })
            handleContinue(filtered[0])
          }
        })
      } else {
        setFilteredLenses(lenses)
        setIsSearching(false)
      }
    },
    [debouncedSearchInput] // Only call effect if debounced search term changes.
  )

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value)
    setIsSearching(true)
    if (scanWizardTree) {
      dispatch({ type: "SET_CURRENT_SCAN_LENS", lens: null })
    }
  }

  const toggleListView = () => {
    setListView(!listView)
  }

  // Automatically focus on search bar upon page load or re-navigation.
  useEffect(() => {
    inventorySearchInput.focus()
  }, [lensesFetched, trie])

  return (
    <div style={{ minHeight: "42vh" }}>
      <Affix>
        <PageHeader
          title={
            <div className={css.inventorySearchField} id="tour-step-9">
              <Input
                onChange={handleSearchInputChange}
                placeholder={SEARCH_PLACEHOLDER}
                className={css.inventorySearch}
                value={searchInput}
                disabled={trie === undefined}
                ref={(input) => (inventorySearchInput = input)}
                allowClear
              />
              {!scanWizardTree && (
                <Switch
                  checkedChildren={<AppstoreOutlined />}
                  unCheckedChildren={<BarsOutlined />}
                  onChange={toggleListView}
                  style={{ marginLeft: "16px" }}
                  defaultChecked
                />
              )}
              {!scanWizardTree && (
                <HiddenTooltip
                  title={<span>Select checkbox on lens to print.</span>}
                  hideIf={batch.size > 0}
                >
                  <Button
                    icon={<PrinterOutlined />}
                    style={{ marginLeft: "16px" }}
                    onClick={() => setShowCombinedModal(true)}
                    disabled={batch.size === 0}
                  >
                    Print Preview
                  </Button>
                </HiddenTooltip>
              )}
            </div>
          }
          className={css.filters}
          extra={!scanWizardTree && `${batch.size} selected`}
        ></PageHeader>
      </Affix>
      {isSearching ? (
        <Loading empty />
      ) : filteredLenses.length > 0 ? (
        <div className={cx(css.layer1, { [css.short]: scanWizardTree })}>
          <Row className={css.layer2}>
            {searchInput === "" && (
              <AddLensCard
                listView={listView}
                handleContinue={handleContinue}
                scanWizard={scanWizardTree}
                topPlacement
              />
            )}
            {filteredLenses.map(
              (lens) =>
                lens.serial_number !== null && (
                  <Col
                    xs={24}
                    sm={listView ? 24 : 12}
                    md={listView ? 24 : 8}
                    lg={listView ? 24 : 6}
                    xl={listView ? 24 : 4}
                    xxl={listView ? 24 : 3}
                    key={lens.lens_id}
                  >
                    <LensCard
                      className={css.card}
                      lens={lens}
                      selectable={!scanWizardTree}
                      batchSelected={batch.has(lens.lens_id)}
                      modifyBatch={modifyBatch}
                      listView={listView}
                      scanWizard={scanWizardTree}
                      handleContinue={handleContinue}
                    ></LensCard>
                  </Col>
                )
            )}
            {searchInput !== "" && (
              <AddLensCard
                listView={listView}
                handleContinue={handleContinue}
                scanWizardTree={scanWizardTree}
              />
            )}
            <CombinedLensModal
              visible={showCombinedModal}
              onCancel={() => setShowCombinedModal(false)}
              lensIds={batch}
            />
          </Row>
        </div>
      ) : !lensesFetched ? (
        <Loading empty />
      ) : (
        <AddLensCard
          listView={listView}
          handleContinue={handleContinue}
          scanWizardTree={scanWizardTree}
        />
      )}
    </div>
  )
}

Inventory.propTypes = {
  scanWizardTree: PropTypes.bool,
  handleContinue: PropTypes.func,
}

export default Inventory
