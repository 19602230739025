import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useGlobalState } from "../../state"
import { firestore as db } from "../../state/firebase"
import css from "./SharingModal.module.css"
import cx from "classnames"
import { Button, Modal, Tag, Divider, Row, Col, Avatar } from "antd"
import {
  CheckOutlined,
  CopyOutlined,
  DeleteOutlined,
  LinkOutlined,
  LoadingOutlined,
  SwapOutlined,
  TeamOutlined,
  WarningOutlined,
} from "@ant-design/icons"
import { generateDateTimeString } from "../../helpers/date"
import { captureException } from "@sentry/react"

const SharingModal = ({
  leftScan,
  rightScan,
  surface,
  compareScans,
  url,
  serialNumber,
  ...props
}) => {
  const [state, dispatch] = useGlobalState()
  const [sharingLink, setSharingLink] = useState()
  const [sharingLinkObject, setSharingLinkObject] = useState({ ready: false })
  const [sharingLinks, setSharingLinks] = useState([])
  const [linkCopied, setLinkCopied] = useState("")

  const handleShareCancel = () => {
    dispatch({ type: "SET_SHARE_MODAL_VISIBLE", visible: false })
  }

  // Post a sharingLink instance as "ready": false, which triggers a Cloud Function
  // to copy surface images into public locations in GCS. Returns Firestore ID of
  // created SharingLink, which functions as shared URL path.
  const handleCreateLink = () => {
    const uid = state.uid || props.user.uid
    db.createSharingLink(
      leftScan,
      rightScan,
      surface,
      state.userOrgId,
      uid,
      serialNumber
    ).then((shareID) => setSharingLink(shareID))
  }

  // Get list of SharingLinks for lens, then check if there already exists a link
  // for selected leftScan, rightScan, and surface.
  const getSharingLinks = (
    left = leftScan,
    right = rightScan,
    scanSurface = surface
  ) => {
    db.getSharingLinksByLens(left.lens_ref)
      .then((links) => {
        let matches = links.filter(
          (sl) => sl.leftScan._id === left._id && sl.surface === scanSurface
        )
        if (rightScan !== undefined) {
          matches = matches.filter(
            (sl) => sl.rightScan && sl.rightScan._id === right._id
          )
        } else {
          matches = matches.filter((sl) => sl.rightScan === null)
        }
        if (matches.length > 0) {
          setSharingLink(matches[0].id)
          setSharingLinkObject(matches[0])
          setSharingLinks(links.filter((sl) => sl !== matches[0]))
          return
        } else {
          setSharingLink(undefined)
          setSharingLinkObject({ ready: false })
          setSharingLinks(links)
        }
      })
      .catch((err) => {
        captureException(err)
        return setSharingLinks([])
      })
  }

  // Store full SharingLink document.
  const onSharingLinkChange = (doc) => {
    if (doc === undefined) return
    setSharingLinkObject(doc)
  }

  // Generate list of links for lens upon leftScan, rightScan, or surface change.
  // (Find a match if one exists.)
  useEffect(() => {
    getSharingLinks(leftScan, rightScan, surface)
  }, [leftScan, rightScan, surface])

  // Register listener for SharingLink "ready" field change.
  useEffect(() => {
    if (sharingLink === undefined) return
    db.onSharingLinkCreate(sharingLink, onSharingLinkChange)
    getSharingLinks()
  }, [sharingLink])

  // Copy text to clipboard using vanilla Javascript.
  const handleShareCopy = (e) => {
    var temp = document.createElement("input")
    temp.type = "text"
    temp.value = e.target.value
    document.body.appendChild(temp)
    temp.select()
    document.execCommand("Copy")
    document.body.removeChild(temp)
    setLinkCopied(e.target.value)
  }

  // DELETE a SharingLink instance from Firestore, which triggers a Cloud Function
  // to remove SharedDocuments folder and images from GCS.
  const handleDeleteLink = (e) => {
    const linkId = e.target.value
    db.deleteSharingLink(linkId)
    if (sharingLink === linkId) {
      setSharingLink(undefined)
      setSharingLinkObject({ ready: false })
    }
    getSharingLinks()
  }

  // Time string in a box for sharingLink title.
  const timeTag = (seconds) => {
    return (
      <Tag className={css.modalDate}>{generateDateTimeString(seconds)}</Tag>
    )
  }

  // Title of sharingLink (e.g. "For scan [07/02/20, 7:04 AM] [front (1000)]").
  const sharingLinkTitle = (leftScan, rightScan, surface) => {
    return rightScan ? (
      <div>
        For scans {timeTag(leftScan.timestamp.seconds)}
        <SwapOutlined style={{ marginRight: "2px" }} />
        {"  "}
        {timeTag(rightScan.timestamp.seconds)}
        <Tag className={css.modalDate}>{surface}</Tag>
      </div>
    ) : (
      <div>
        For scan {timeTag(leftScan.timestamp.seconds)}{" "}
        <Tag className={css.modalDate}>{surface}</Tag>
      </div>
    )
  }

  // Copy Button to copy sharingLink to clipboard,
  const copyLinkButton = (href, ready = true) => {
    return linkCopied === href ? (
      <Avatar
        style={{ backgroundColor: "var(--green)" }}
        icon={<CheckOutlined />}
      />
    ) : (
      <div>
        <Button
          value={href}
          onClick={handleShareCopy}
          className={cx(css.shareActionButton, "greenBackground", "fadeIn")}
          shape="circle"
          title="Copy link to clipboard"
          disabled={!ready}
        >
          {ready ? <CopyOutlined /> : <LoadingOutlined />}
        </Button>
      </div>
    )
  }

  // Trashcan button to remove SharingLink instance and copied images.
  const deleteSharingLinkButton = (linkId, ready = true) => {
    return (
      <Button
        value={linkId}
        onClick={handleDeleteLink}
        shape="circle"
        className={cx(css.shareActionButton, "redBackground", "fadeIn")}
        title="Revoke link access"
        disabled={!ready}
      >
        {ready ? <DeleteOutlined /> : <LoadingOutlined />}
      </Button>
    )
  }

  // Link title and action button for Modal section "Links for this Lens."
  const sharingLinkRow = (link) => {
    const href = `${url.host}/shared/${link.id}`
    return (
      <Row key={`shareRow${link.id}`} className={css.shareRow}>
        <Col span={1}></Col>
        <Col span={19}>
          {" "}
          {sharingLinkTitle(link.leftScan, link.rightScan, link.surface)}
        </Col>
        <Col span={2} className={css.endJustify}>
          {copyLinkButton(href)}
        </Col>
        <Col span={2} className={css.endJustify}>
          {deleteSharingLinkButton(link.id, true)}
        </Col>
      </Row>
    )
  }

  return (
    <Modal
      maskStyle={{ backdropFilter: "blur(8px)" }}
      visible={state.shareModalVisible}
      onCancel={handleShareCancel}
      className={css.shareModal}
      footer={null}
      width={640}
      centered
    >
      <div className={css.modalTitle}>
        <LinkOutlined className={css.shareIcon} /> Get Shareable Link
      </div>
      <br />
      <Row className={css.modalDescription}>
        <Col span={18}>{sharingLinkTitle(leftScan, rightScan, surface)}</Col>
        <Col span={6} className={css.endJustify}>
          {sharingLink === undefined ? (
            <Button type="primary" onClick={handleCreateLink}>
              Get Link
            </Button>
          ) : (
            deleteSharingLinkButton(sharingLink, sharingLinkObject.ready)
          )}
        </Col>
      </Row>
      {sharingLink !== undefined && (
        <div>
          <Row className={css.mainShareRow}>
            <Col span={1}></Col>
            <Col span={21}>{`${url.host}/shared/${sharingLink}`}</Col>
            <Col span={2} className={css.endJustify}>
              {copyLinkButton(
                `${url.host}/shared/${sharingLink}`,
                sharingLinkObject.ready
              )}
            </Col>
          </Row>
          <div className={css.modalWarning}>
            <WarningOutlined />
            {"  "}
            Warning: <b>Anyone with the link</b> can view{" "}
            {compareScans ? "these scans" : "this scan"}!
          </div>
        </div>
      )}
      {sharingLinks.length > 0 && (
        <div>
          <Divider />
          <div className={css.modalShareHistory}>
            <div className={css.modalTitle}>
              <TeamOutlined className={css.shareIcon} /> Links for this Lens
            </div>
            <br />
            {sharingLinks.map((sharingLink) => sharingLinkRow(sharingLink))}
          </div>
        </div>
      )}
    </Modal>
  )
}

SharingModal.propTypes = {
  leftScan: PropTypes.object,
  rightScan: PropTypes.object,
  surface: PropTypes.string,
  compareScans: PropTypes.bool,
  url: PropTypes.object,
  serialNumber: PropTypes.string,
  user: PropTypes.object,
}

export default SharingModal
