import React from "react"
import PropTypes from "prop-types"
import css from "./index.module.css"

export default function Header({ children }) {
  return <div className={css.header}>{children}</div>
}

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
