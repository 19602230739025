import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  ArrowsAltOutlined,
  DownCircleOutlined,
  HighlightOutlined,
  RedoOutlined,
  ShareAltOutlined,
  UndoOutlined,
  UpCircleOutlined,
} from "@ant-design/icons"
import { Button, Slider, Modal } from "antd"
import { Tools } from "react-sketch"
import { SCRATCH_COLORS } from "../../state/constants"
import cx from "classnames"
import css from "./ControlPanel.module.css"

// Sub-component that creates color selection boxes and shows state
// about which color is currently selected.
const ColorBox = ({ currentColor, controls, color, setControls }) => {
  return (
    <div
      onClick={() => setControls({ ...controls, color })}
      className={css.colorSelector}
      style={{ backgroundColor: color }}
    >
      {currentColor === color && "•"}
    </div>
  )
}

ColorBox.propTypes = {
  currentColor: PropTypes.string,
  controls: PropTypes.object,
  color: PropTypes.string,
  setControls: PropTypes.func,
}

const ControlPanel = ({
  controls,
  setControls,
  undo,
  redo,
  handleAddScratch,
  saving,
  numScratches,
}) => {
  const [sharing, setSharing] = useState(false)

  const handleSave = () => {
    handleAddScratch()
  }
  return (
    <div className={css.controls} id="tour-step-5">
      <div className={css.undoRedo}>
        <button className={css.undoButton} onClick={undo}>
          undo
          <br />
          <UndoOutlined />
        </button>
        <button className={css.undoButton} onClick={redo}>
          redo
          <br />
          <RedoOutlined />
        </button>
      </div>
      <div className={css.tools}>
        TOOLS
        <div className={css.toolsContainer}>
          <div className={css.toolButtons}>
            <div className={css.toolButton}>
              <div>Scratch</div>
              <Button
                type={controls.tool === Tools.Pencil ? "primary" : "secondary"}
                onClick={() => setControls({ ...controls, tool: Tools.Pencil })}
                shape="circle"
                icon={<HighlightOutlined />}
              />
            </div>
            <div className={css.toolButton}>
              <div>Select</div>
              <Button
                type={controls.tool === Tools.Select ? "primary" : "secondary"}
                onClick={() => setControls({ ...controls, tool: Tools.Select })}
                shape="circle"
                disabled={numScratches === 0}
                icon={<ArrowsAltOutlined />}
              />
            </div>
          </div>
          <div className={css.scratchSize}>
            <div>Scratch Size</div>
            <Slider
              value={controls.lineWidth}
              onChange={(value) =>
                setControls({ ...controls, lineWidth: value })
              }
              min={1}
              max={10}
            />
          </div>
        </div>
      </div>
      <div
        onClick={() =>
          setControls({ ...controls, expanded: !controls.expanded })
        }
        className={css.optionsButton}
      >
        <div>
          {controls.expanded ? "fewer" : "more"} <br /> options
        </div>
        {controls.expanded ? <DownCircleOutlined /> : <UpCircleOutlined />}
      </div>
      <div className={cx(css.colors, { [css.hidden]: !controls.expanded })}>
        COLORS
        <div className={css.colorFlex}>
          <div className={css.colorContainer}>
            {Object.keys(SCRATCH_COLORS).map((key) => (
              <ColorBox
                key={SCRATCH_COLORS[key]}
                setControls={setControls}
                controls={controls}
                currentColor={controls.color}
                color={SCRATCH_COLORS[key]}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={cx(css.share, { [css.hidden]: !controls.expanded })}>
        <Button
          onClick={() => setSharing(true)}
          type="secondary"
          icon={<ShareAltOutlined />}
        >
          Share
        </Button>
        <Modal
          maskStyle={{ backdropFilter: "blur(8px)" }}
          footer={null}
          onCancel={() => setSharing(false)}
          visible={sharing}
          bodyStyle={{
            padding: 0,
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
          centered
        >
          <h2>Share functionality coming soon!</h2>
        </Modal>
      </div>
      <div className={css.save}>
        <Button loading={saving} onClick={handleSave} type="primary">
          Save
        </Button>
      </div>
    </div>
  )
}

ControlPanel.propTypes = {
  controls: PropTypes.object,
  setControls: PropTypes.func,
  undo: PropTypes.func,
  redo: PropTypes.func,
  handleAddScratch: PropTypes.func,
  saving: PropTypes.bool,
  numScratches: PropTypes.number,
}

export default ControlPanel
