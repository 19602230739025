import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button, Card, Dropdown, Menu, Modal, Row, Col } from "antd"
import { useGlobalState } from "../../../state"
import { DEMO_LENS } from "../../../state/constants"
import { generateScanID } from "../../../helpers/generateScanID"
import Header from "../../common/Header"
import css from "./index.module.css"
import cx from "classnames"
import {
  MoreOutlined,
  DesktopOutlined,
  ToolOutlined,
  BarcodeOutlined,
} from "@ant-design/icons"
import { MACHINE, STEPS } from "../../../state/constants"
import { firestore as db } from "../../../state/firebase"

const { Meta } = Card

export default function StartScan({ setStep, user }) {
  const [state, dispatch] = useGlobalState()
  const [serviceModalVisible, setServiceModalVisible] = useState(false)
  const [serviceStatus, setServiceStatus] = useState("")
  const [serialNumberModalVisible, setSerialNumberModalVisible] = useState(
    false
  )

  useEffect(() => {
    if (state.scanners.length === 1) {
      dispatch({ type: "SET_SCANNER", scanner: state.scanners[0] })
      handleStartClick()
    }
  }, [state.scanners])

  useEffect(() => {
    if (state.scanner === null || state.scanner === undefined) return
    db.onServiceChange(
      state.userOrgId,
      state.scanner._id,
      onServiceStatusChange
    )
  }, [state.scanner])

  useEffect(() => {
    if (serviceStatus === MACHINE.READY && serviceModalVisible) {
      setServiceModalVisible(false)
    }
  }, [serviceStatus])

  const mapServiceStatusToDialog = (status) => {
    switch (status) {
      case MACHINE.STARTING_SERVICE:
        return "Scanner moving to service position..."
      case MACHINE.READY_TO_SERVICE:
        return `Scanner is in service position.\n
        It is now safe to service ${
          state.scanner && state.scanner.human_name
        }.\n
        Press 'Done' when you're ready to use the machine for scanning again.`
      case MACHINE.STOPPING_SERVICE:
        return "Moving arms back to home positions..."
      default:
        return `It is now safe to scan on ${
          state.scanner && state.scanner.human_name
        }!`
    }
  }

  const onServiceStatusChange = (doc) => {
    setServiceStatus(doc.status)
  }

  const showModal = () => setServiceModalVisible(true)

  const handleScannerSelect = (scanner) => {
    dispatch({ type: "SET_SCANNER", scanner: scanner })
  }

  const handleStartClick = () => {
    if (state.scanner && state.scanner.status === MACHINE.SCANNING) {
      dispatch({
        type: "SET_CURRENT_SCAN_LENS",
        lens: state.scanner.user_scan_params.lens,
      })
      dispatch({ type: "SET_SCAN_ID", scanID: state.scanner.scan_id })
      setStep(STEPS.SCANNING)
    } else {
      setStep(STEPS.SELECT_LENS)
    }
  }

  const handleStartDemoClick = () => {
    const scanID = generateScanID()
    dispatch({ type: "SET_SCAN_ID", scanID: scanID })
    dispatch({ type: "SET_CURRENT_SCAN_LENS", lens: DEMO_LENS })
    dispatch({ type: "SET_DEMO_MODE", demoMode: true })
    db.getScanner(state.userOrgId, state.scanner.id).then((doc) => {
      db.startDemo(state.userOrgId, doc.id, user.email, scanID).then((res) =>
        console.log(res)
      )
    })
    setStep(STEPS.SCANNING)
  }

  const handleStartServiceClick = () => {
    dispatch({ type: "SET_SERVICE_MODE", serviceMode: true })
    db.getScanner(state.userOrgId, state.scanner.id).then((doc) =>
      db.startService(state.userOrgId, doc.id, user.email)
    )
    showModal()
  }

  const handleStopServiceClick = () => {
    dispatch({ type: "SET_SERVICE_MODE", serviceMode: false })
    db.stopService(state.userOrgId, state.scanner.id)
  }

  const handleViewSerialNumberClick = () => {
    setSerialNumberModalVisible(true)
  }

  // Get and display initials of scanner ("Optimus Prime" -> "OP").
  const scannerTitle = (name) => {
    if (name === undefined) return
    const initials = name
      .split(" ")
      .map((words) => words[0])
      .join("")
    return <h1 style={{ margin: "0", color: "#bfbfbf" }}>{initials}</h1>
  }

  const menu = (
    <Menu>
      <Menu.Item key="demo-menu-item" onClick={handleStartDemoClick}>
        <DesktopOutlined /> Start Demo
      </Menu.Item>
      <Menu.Item key="service-menu-item" onClick={handleStartServiceClick}>
        <ToolOutlined /> Service Scanner
      </Menu.Item>
      <Menu.Item
        key="serial-number-menu-item"
        onClick={handleViewSerialNumberClick}
      >
        <BarcodeOutlined /> View Serial Number
      </Menu.Item>
    </Menu>
  )
  return (
    <div className={css.container}>
      {state.scanner !== undefined && state.scanner !== null && (
        <Modal
          title={`${state.scanner.human_name} Serial Number`}
          visible={serialNumberModalVisible}
          type="info"
          centered
          closable
          onCancel={() => setSerialNumberModalVisible(false)}
          footer={null}
        >
          {`${state.scanner.id}`}
        </Modal>
      )}

      <Modal
        title={`Servicing ${state.scanner && state.scanner.human_name}`}
        visible={serviceModalVisible}
        onOk={handleStopServiceClick}
        centered
        closable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            ghost
            disabled={false}
            onClick={handleStopServiceClick}
          >
            Done
          </Button>,
        ]}
      >
        {mapServiceStatusToDialog(serviceStatus)
          .split("\n")
          .map((line) => (
            <p key={line}>{line}</p>
          ))}
      </Modal>

      <div className={css.innerContainer}>
        <Header>Welcome!</Header>
        <Row className={css.layer2} type="flex" justify="space-around">
          {state.scanners.length > 0 &&
            state.scanners.map((scanner) => (
              <Col xs={state.scanners.length === 1 ? 24 : 12} key={scanner._id}>
                <Card
                  hoverable
                  bordered
                  onClick={() => handleScannerSelect(scanner)}
                  className={cx(css.scannerCard, {
                    [css.selected]:
                      state.scanner && state.scanner._id === scanner._id,
                  })}
                >
                  <Meta
                    className={css.scannerTitle}
                    title={scannerTitle(scanner.human_name)}
                    description={scanner.human_name}
                  />
                </Card>
              </Col>
            ))}
        </Row>
        <div className={css.button}>
          {state.scanner && (
            <Dropdown.Button
              onClick={handleStartClick}
              overlay={menu}
              placement="bottomRight"
              icon={<MoreOutlined />}
            >
              {state.scanner && state.scanner.status === MACHINE.SCANNING
                ? "View Scan in Progress"
                : "Start Scan"}
            </Dropdown.Button>
          )}
          {state.scanners.length === 0 && (
            <Button type="primary" ghost disabled>
              No Scanners Found
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

StartScan.propTypes = {
  user: PropTypes.object,
  setStep: PropTypes.func,
}
