import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Input, Row, Col, Button, Radio, Divider } from "antd"
import Header from "../../common/Header"
import { useGlobalState } from "../../../state"
import css from "./index.module.css"
import { firestore as db } from "../../../state/firebase"
import { STEPS } from "../../../state/constants"
import { PlusOutlined } from "@ant-design/icons"

const InputGroup = Input.Group

export default function SelectSerialNumber({ setStep }) {
  const [serialNumbers, setSerialNumbers] = useState([])
  const [serialNumber, setSerialNumber] = useState(null)
  const [selectEnabled, setSelectEnabled] = useState(true)
  const [lensId, setLensId] = useState(null)
  const [newSerialNumber, setNewSerialNumber] = useState(false)
  const [state, dispatch] = useGlobalState()

  useEffect(() => {
    db.getSerialNumbers(state.currentScanLens.type_id, state.userOrgId).then(
      (arr) => {
        setSerialNumbers(arr)
        if (arr.length === 1) {
          setSerialNumber(arr[0].serial_number)
          setLensId(arr[0].id)
        }
        if (arr.length === 0) {
          setNewSerialNumber(true)
        }
      }
    )
  }, [])

  const displayForm = () => {
    setLensId(null)
    setNewSerialNumber(true)
  }

  const handleContinue = (currentScanLens = null) => {
    let csl = currentScanLens || state.currentScanLens
    // Check for duplicate entry of an existing serial_number.
    const matchingSN = serialNumbers.find(
      (x) => x.serial_number === serialNumber
    )
    if (matchingSN !== undefined) {
      csl.serial_number = matchingSN.serial_number
      csl.id = matchingSN.id
    } else {
      if (lensId === null && matchingSN === undefined) {
        // New serial_number -- post to Firestore.
        db.addSerialNumber(serialNumber, csl.type_id, state.userOrgId).then(
          (doc) => {
            csl.id = doc.id
            csl.serial_number = doc.serial_number
          }
        )
      } else {
        // Existing serial_number -- use found data.
        csl.serial_number = serialNumbers.find(
          (obj) => obj.id === lensId
        ).serial_number
        csl.id = lensId
      }
    }
    dispatch({
      type: "SET_CURRENT_SCAN_LENS",
      lens: csl,
    })
    dispatch({
      type: "SET_CALIBRATION_SCAN",
      calibrationScan: !csl.calibrated_front_rear,
    })
    if (csl.calibrated_front_rear) {
      setStep(STEPS.FINAL_CHECKLIST)
    } else {
      setStep(STEPS.CALIBRATE_LENS)
    }
  }

  const handleSelectChange = (e) => setLensId(e.target.value)

  const handleChange = (e) => {
    const input = e.target.value
    if (input.length === 0) {
      setSerialNumber(null)
      setSelectEnabled(true)
      return
    }
    setLensId(null)
    setSelectEnabled(false)
    setSerialNumber(input)
  }

  return (
    <div>
      <Header>Select Serial Number</Header>
      {serialNumbers.length > 0 && (
        <Radio.Group
          onChange={handleSelectChange}
          value={lensId}
          style={{ width: "100%" }}
        >
          {serialNumbers.map((sn) => (
            <div key={sn.id}>
              <Row gutter={8}>
                <Col span={24}>
                  <Radio
                    className={css.radio}
                    value={sn.id}
                    disabled={!selectEnabled}
                  >
                    {sn.serial_number}
                  </Radio>
                </Col>
              </Row>
              <Divider className={css.divider} />
            </div>
          ))}
        </Radio.Group>
      )}
      <Row gutter={8}>
        {newSerialNumber ? (
          <Col span={24}>
            <Input
              onChange={handleChange}
              onPressEnter={handleContinue}
              placeholder="Lens Serial Number"
            />
          </Col>
        ) : (
          <InputGroup>
            <Col span={4}>
              <div>
                <Button
                  type="primary"
                  ghost
                  onClick={displayForm}
                  disabled={newSerialNumber}
                >
                  <PlusOutlined />
                </Button>
              </div>
            </Col>
            <Col span={20}>Add Serial Number</Col>{" "}
          </InputGroup>
        )}
      </Row>
      <br />

      <div className={css.buttonGroup}>
        <div className={css.right}>
          <Button
            disabled={lensId === null && serialNumber === null}
            type="primary"
            ghost
            onClick={handleContinue}
            block
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  )
}

SelectSerialNumber.propTypes = {
  setStep: PropTypes.func,
}
