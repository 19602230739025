import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button, Progress, Typography, Skeleton } from "antd"
import Header from "../../common/Header"
import Lens from "../../common/Lens"
import { useGlobalState } from "../../../state"
import css from "./index.module.css"
import { firestore as db } from "../../../state/firebase"
import {
  MACHINE,
  CURRENT_SCAN,
  STEPS,
  SCAN_STATUS,
} from "../../../state/constants"
import { LoadingOutlined } from "@ant-design/icons"
import { generateScanID } from "../../../helpers/generateScanID"

export default function Scanning({ setStep, user }) {
  const handleStop = () => {
    db.stopScanning(state.userOrgId, state.scanner.id)
  }

  const handleRestart = () => {
    dispatch({ type: "START_ANOTHER_SCAN" })
    setStep(STEPS.SELECT_LENS)
  }

  const [state, dispatch] = useGlobalState()
  const [showSkeleton, setShowSkeleton] = useState(true)
  const [scanStatus, setScanStatus] = useState("UPLOADING")
  const [scannerStatus, setScannerStatus] = useState({
    percentComplete: 0,
    status: MACHINE.READY,
    currentScan: CURRENT_SCAN.STARTED,
    currentScanVerbose: "",
  })

  const [progressFeatures, setProgressFeatures] = useState({
    buttonText: "Stop",
    strokeColor: {
      "0%": "#A288FF",
      "99%": "#CB49EF",
    },
    icon: "active",
    headerText: "Scanning!",
    description: "Scan in progress",
    buttonAction: handleStop,
  })

  const onProgressChange = (doc) => {
    if (doc.scan_id !== state.scanID) return
    let currentScan = doc.current_scan.startsWith("error")
      ? CURRENT_SCAN.ERROR
      : doc.current_scan
    let currentProgress = doc.current_scan.startsWith("error")
      ? 100
      : doc.percent_complete
    setProgressFeatures(mapScanToFeatures(currentScan, scanStatus))
    setScannerStatus({
      percentComplete: currentProgress,
      status: doc.status,
      currentScan: currentScan,
      currentScanVerbose: doc.current_scan,
    })
    return setShowSkeleton(false)
  }

  const onScanProgressChange = (doc) => {
    if (doc.id !== state.scanID) return
    if (Object.keys(doc) < 1) return
    if (doc.status !== SCAN_STATUS.STARTED) {
      let currentScanStatus = CURRENT_SCAN.COMPLETED
      if (doc.status === SCAN_STATUS.STOPPED) {
        currentScanStatus = CURRENT_SCAN.STOPPED
      }
      setProgressFeatures(mapScanToFeatures(currentScanStatus, doc.status))
    }
    setScanStatus(doc.status)
  }

  const mapScanToFeatures = (status, scanStatus) => {
    switch (status) {
      case CURRENT_SCAN.ERROR:
        return {
          strokeColor: "#cf1322", // Antd red-7.
          buttonText: "Reset",
          icon: "exception",
          headerText: "ERROR",
          description: "Error",
          buttonAction: handleRestart,
        }
      case CURRENT_SCAN.STOPPING:
        return {
          strokeColor: "#8c8c8c", // Antd gray-8.
          buttonText: "Reset",
          icon: "exception",
          headerText: "Stopped",
          description: "Stopping scan...",
          buttonAction: handleRestart,
        }
      case CURRENT_SCAN.STOPPED:
        return {
          strokeColor: "#8c8c8c", // Antd gray-8.
          buttonText: "Start Over",
          icon: "exception",
          headerText: "Stopped",
          description: "Stopped scan.",
          buttonAction: handleRestart,
        }
      case CURRENT_SCAN.COMPLETED:
        return scanStatus === SCAN_STATUS.UPLOADING
          ? {
              strokeColor: "#389e0d", // Antd green-7.
              buttonText: "Start Another Scan",
              icon: "active",
              headerText: "Scan Complete!",
              description: "Scan complete! Uploading...",
              buttonAction: handleRestart,
            }
          : {
              strokeColor: "#389e0d", // Antd green-7.
              buttonText: "Start Another Scan",
              icon: "success",
              headerText: "Scan Complete!",
              description: "Scan complete and uploaded.",
              buttonAction: handleRestart,
            }
      default:
        return {
          strokeColor: { "0%": "#A288FF", "99%": "#CB49EF" }, // Tracis purple.
          buttonText: "Stop",
          icon: "active",
          headerText: "Scanning!",
          description: "Scan in progress...",
          buttonAction: handleStop,
        }
    }
  }

  useEffect(() => {
    db.onScannerStatusChange(
      state.userOrgId,
      state.scanner.id,
      onProgressChange
    )
  }, [])

  useEffect(() => {
    db.onScanStatusChange(state.userOrgId, state.scanID, onScanProgressChange)
  }, [])

  // START TEMPORARY CODE
  // TODO: Remove this code
  // This code is responsible for sending the command to start scanning again if we
  // remain on the skeleton view for more than 5 seconds.
  const reformatLensKeys = (oldLens) => {
    const defaults = {
      fstop: 1.8,
      image_diameter: 32,
      mount: "PL",
    }

    Object.keys(oldLens).forEach(
      (key) => oldLens[key] === null && delete oldLens[key]
    )

    const lens = Object.assign({}, defaults, oldLens)
    lens.focal_length = oldLens.focal_length_mm
    delete lens["calibrated_front_rear"]
    delete lens["focal_length_mm"]
    if (lens.newLens) delete lens["newLens"]

    return lens
  }
  const restartScan = () => {
    console.log("Calling startScanning again. It didn't work the first time.")
    const { currentScanLens } = state
    const { type_id, newLens } = currentScanLens
    let lens

    if (newLens) {
      lens = currentScanLens
    } else {
      lens = state.lensTypes.find((lensType) => lensType.type_id === type_id)
    }
    if (lens === undefined) return
    lens.lens_id = currentScanLens.lens_id
    lens.serial_number = currentScanLens.serial_number
    const scanID = generateScanID()
    db.startScanning(
      state.userOrgId,
      state.scanner.id,
      reformatLensKeys(lens),
      user,
      scanID,
      state.calibrationScan
    )
  }

  useEffect(() => {
    // This if showSkeleton is false is unusual but it works
    // while if showSkeleton is true, setTimeout does not have the
    // intended effect of only running once if showSkeleton is true after 3 seconds.
    const timeoutId = setTimeout(restartScan, 3000)
    if (showSkeleton === false) {
      clearTimeout(timeoutId)
    }

    return () => clearTimeout(timeoutId)
  }, [showSkeleton])

  // END TEMPORARY CODE

  return (
    <div>
      {showSkeleton ? (
        <Skeleton active={true} />
      ) : (
        <div>
          <Header>{progressFeatures.headerText}</Header>
          <div className={css.scanner}>{state.scanner.human_name}</div>
          <div className={css.lens}>
            <Lens lens={state.currentScanLens} />
          </div>
          <div style={{ display: "flex" }}>
            <Progress
              strokeColor={progressFeatures.strokeColor}
              percent={scannerStatus.percentComplete}
              status={progressFeatures.icon}
              showInfo={
                (scannerStatus.percentComplete > 0 &&
                  scannerStatus.percentComplete < 100) ||
                scannerStatus.currentScan === CURRENT_SCAN.STOPPED ||
                scanStatus === "COMPLETED"
              }
              style={
                (scannerStatus.percentComplete === 0 &&
                  scannerStatus.currentScan !== CURRENT_SCAN.STOPPED) ||
                (scannerStatus.percentComplete === 100 &&
                  scanStatus !== "COMPLETED")
                  ? { paddingRight: "18px" }
                  : {}
              }
            />
            {((scannerStatus.percentComplete === 0 &&
              scannerStatus.currentScan !== CURRENT_SCAN.STOPPED) ||
              (scannerStatus.percentComplete === 100 &&
                scanStatus !== "COMPLETED")) && (
              <div style={{ marginRight: "8px" }}>
                <LoadingOutlined style={{ fontSize: "x-small" }} />
              </div>
            )}
          </div>
          <Typography
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {progressFeatures.description}
          </Typography>
          <div className={css.button}>
            <Button
              type="primary"
              ghost
              size="large"
              onClick={progressFeatures.buttonAction}
              block
            >
              {progressFeatures.buttonText}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

Scanning.propTypes = {
  setStep: PropTypes.func,
  user: PropTypes.object,
}
