import React, { useState } from "react"
import PropTypes from "prop-types"
import css from "./UnSignedInApp.module.css"
import { Button, Divider, Input, Form, Typography } from "antd"
import { Route, BrowserRouter, Switch, Link, Redirect } from "react-router-dom"
import { ArrowRightOutlined, GoogleOutlined } from "@ant-design/icons"
import SignUp from "../../common/SignUp"
import WelcomeHeader from "../../common/WelcomeHeader"
import Loading from "../../common/Loading"

const { Text } = Typography

export const UnSignedInApp = ({
  signInWithGoogle,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  ...props
}) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/signup" {...props}>
          <SignUp
            signInWithGoogle={signInWithGoogle}
            createUserWithEmailAndPassword={createUserWithEmailAndPassword}
          />
        </Route>
        <Route path="/signin" {...props}>
          <SignIn
            signInWithGoogle={signInWithGoogle}
            signInWithEmailAndPassword={signInWithEmailAndPassword}
          />
        </Route>
        <Route path="/settings" {...props}>
          <Loading empty />
        </Route>
        <Route exact path="/" {...props}>
          <Redirect to="/signin" />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

UnSignedInApp.propTypes = {
  signInWithGoogle: PropTypes.func,
  signInWithEmailAndPassword: PropTypes.func,
  createUserWithEmailAndPassword: PropTypes.func,
}

const SignIn = ({ signInWithGoogle, signInWithEmailAndPassword }) => {
  const [form] = Form.useForm()
  const [badCredentials, setBadCredentials] = useState("")

  const emailPasswordSignIn = () => {
    setBadCredentials("")
    const formValues = form.getFieldsValue()
    return signInWithEmailAndPassword(
      formValues.email,
      formValues.password
    ).then(() => {
      setBadCredentials("The email or password you entered is incorrect.")
    })
  }

  return (
    <div className={css.signInContainer}>
      <div className={css.module}>
        <WelcomeHeader />
        <h2>Sign In</h2>
        <Divider />
        <Button
          type="primary"
          ghost
          onClick={signInWithGoogle}
          icon={<GoogleOutlined />}
          block
        >
          Sign in with Google
        </Button>
        <Divider>OR</Divider>
        <Form
          layout="vertical"
          form={form}
          name="signin"
          onFinish={emailPasswordSignIn}
          scrollToFirstError
          style={{ width: "100%" }}
        >
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "Invalid e-mail format",
              },
              {
                required: true,
                message: "E-mail is required",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Password is required",
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Text type="danger">{badCredentials}</Text>

          <br />
          <Form.Item>
            <Button type="primary" htmlType="submit" ghost block>
              Sign in with Email & Password
            </Button>
          </Form.Item>
        </Form>
        <Divider />
        New User?{"  "}
        <Link to="/signup">
          Sign Up <ArrowRightOutlined />
        </Link>
      </div>
    </div>
  )
}

SignIn.propTypes = {
  signInWithGoogle: PropTypes.func,
  signInWithEmailAndPassword: PropTypes.func,
}
