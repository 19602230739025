import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import css from "./index.module.css"
import { BarcodeOutlined } from "@ant-design/icons"

const LensTitle = ({ lens, listView = false, selectable = false }) => {
  return (
    <div className={cx(css.titleStyle, { [css.inline]: listView })}>
      <h3>{lens.make}</h3>
      <h4 className={css.model}>{lens.model}</h4>
      <h4 className={css.focalLength}>{lens.focal_length_description}mm</h4>
      <h5>{lens.serial_number}</h5>
      {lens.barcode !== null &&
        lens.barcode !== undefined &&
        lens.barcode !== "" && (
          <h5
            className={cx({
              [css.pullRight]: !listView,
              [css.pullLeft]: listView && selectable,
            })}
          >
            <BarcodeOutlined /> {lens.barcode}
          </h5>
        )}
    </div>
  )
}

LensTitle.propTypes = {
  lens: PropTypes.object,
  listView: PropTypes.bool,
  selectable: PropTypes.bool,
}

export default LensTitle
