import { generateLensKeywords } from "../../helpers/search"
import { COLLECTION } from "../constants"
import {
  getDb,
  describeLensModel,
  sortLenses,
  getLensRefs,
  associateLensWithRefs,
} from "./helpers"

const lenses = {
  addSerialNumber: async (serialNumber, lensTypeID, orgID, barcode = null) => {
    const db = await getDb()
    const lensTypeRef = db.collection(COLLECTION.LENS_TYPES).doc(lensTypeID)
    let lens = {
      serial_number: serialNumber,
      lens_type_ref: lensTypeRef,
    }
    if (barcode !== null) lens.barcode = barcode

    const lensRef = await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgID)
      .collection(COLLECTION.LENSES)
      .add(lens)
    const lensData = await lensRef.get()
    return { id: lensData.id, ...lensData.data() }
  },
  getSerialNumbers: async (lensTypeID, orgID) => {
    const db = await getDb()
    const lensTypeRef = db.collection(COLLECTION.LENS_TYPES).doc(lensTypeID)
    const serialNumberDocs = await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgID)
      .collection(COLLECTION.LENSES)
      .where("lens_type_ref", "==", lensTypeRef)
      .get()
    return serialNumberDocs.docs.map((doc) => {
      return { id: doc.id, ...doc.data() }
    })
  },
  getLensByTypeID: async (lensTypeID) => {
    const db = await getDb()
    const snapshot = await db
      .collection(COLLECTION.LENS_TYPES)
      .doc(lensTypeID)
      .get()
    const serialNumberDocs = await db
      .collection(COLLECTION.LENS_TYPES)
      .doc(lensTypeID)
      .collection(COLLECTION.LENSES)
      .get()
    const serialNumbers = serialNumberDocs.docs.map((doc) => {
      let data = doc.data()
      return data.serial_number
    })
    return {
      ...snapshot.data(),
      typeID: snapshot.id,
      serialNumbers: serialNumbers,
    }
  },
  updateLens: async (orgId, lensId, updateFields) => {
    const db = await getDb()
    return await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .doc(lensId)
      .update(updateFields)
  },
  getLens: async (orgId, lensId) => {
    const db = await getDb()
    const lensDoc = await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .doc(lensId)
      .get()
    if (!lensDoc.exists) {
      throw Error("Lens does not exist: " + lensDoc.ref.path)
    }

    const lensData = lensDoc.data()
    const lensTypeDoc = await db
      .collection(COLLECTION.LENS_TYPES)
      .doc(lensData.lens_type_ref.id)
      .get()

    return {
      ...lensTypeDoc.data(),
      ...lensData,
      typeID: lensData.lens_type_ref.id,
      serial_number: lensData.serial_number,
      lens_id: lensId,
    }
  },
  getLenses: async (orgId) => {
    const db = await getDb()
    const snapshot = await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .get()

    let lensesByType = {}

    let lenses = snapshot.docs.map((doc) => {
      return { lens_id: doc.id, ...doc.data() }
    })

    lenses = lenses.filter((lens) => lens.lens_type_ref !== undefined)
    let lensTypePromises = lenses.map((lens) => lens.lens_type_ref.get())
    const lensTypeData = await Promise.all(lensTypePromises)
    lenses.forEach((lens, i) => {
      if (lensesByType[lens.lens_type_ref.id] !== undefined) {
        lensesByType[lens.lens_type_ref.id].lenses.push(lens)
      } else {
        lensesByType[lens.lens_type_ref.id] = {
          ...describeLensModel(lensTypeData[i].data()),
          lenses: [lens],
        }
      }
    })
    lensesByType = Object.values(lensesByType)
    lensesByType = lensesByType.filter(
      (lens) =>
        lens.make !== undefined &&
        lens.model !== undefined &&
        lens.focal_length_mm !== undefined
    )
    return sortLenses(Object.values(lensesByType))
  },
  onLensesChange: async (orgId, onDone) => {
    const db = await getDb()
    // TODO: This lenses array is suspect and should be removed.
    // It is initialized when onLensesChange is first run and then gets modified
    // every time onSnapshot is called. This array is acting as a kind of state
    // that is uncontrolled by React.
    const lenses = []
    return db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .onSnapshot(async (snapshot) => {
        let addedLenses = []
        let addedLensPromises = []
        let changedLenses = []
        let changedLensPromises = []
        const changes = snapshot.docChanges()
        const counter = changes.length

        for (var i = 0; i < counter; i++) {
          const change = changes[i]
          if (change.doc === undefined) continue
          let lens = change.doc.data()
          if (lens.lens_type_ref === undefined || lens.lens_type_ref === null) {
            continue
          }
          // Not listening for change.type === "deleted" as there's
          // currently no UI mechanism for users to delete lenses.
          if (change.type === "added") {
            addedLenses.push({ lens_id: change.doc.id, ...lens })
            addedLensPromises.push(getLensRefs(lens))
          }
          if (change.type === "modified") {
            changedLenses.push({ lens_id: change.doc.id, ...lens })
            changedLensPromises.push(getLensRefs(lens))
          }
        }
        const addedLensRefs = await Promise.all(addedLensPromises)
        while (addedLenses.length > 0) {
          let lens = addedLenses.shift()
          let refs = addedLensRefs.shift()
          lens = associateLensWithRefs(lens, refs)
          if (
            lens.make === undefined ||
            lens.model === undefined ||
            lens.focal_length_mm === undefined ||
            lens.serial_number === undefined
          ) {
            continue
          }
          lens = describeLensModel(lens)
          const terms = generateLensKeywords(lens)
          terms.forEach((term, i) => (lens[`_term${i}`] = term))
          lenses.push(lens)
        }
        const changedLensRefs = await Promise.all(changedLensPromises)

        while (changedLenses.length > 0) {
          let lens = changedLenses.shift()
          let refs = changedLensRefs.shift()
          lens = associateLensWithRefs(lens, refs)
          if (
            lens.make === undefined ||
            lens.model === undefined ||
            lens.focal_length_mm === undefined ||
            lens.serial_number === undefined
          ) {
            continue
          }
          lens = describeLensModel(lens)
          const terms = generateLensKeywords(lens)
          terms.forEach((term, i) => (lens[`_term${i}`] = term))

          for (let i in lenses) {
            if (lenses[i].lens_id === lens.lens_id) {
              lenses[i] = lens
            }
          }
        }
        onDone(lenses, counter)
      })
  },
}

export default lenses
