import React from "react"
import PropTypes from "prop-types"
import { useGlobalState } from "../../../state"
import { Modal, Tabs } from "antd"
import css from "./index.module.css"
import LensTitle from "../LensTitle"
import ScansTab from "../../ScansTab"
import Scratches from "../../Scratches"
import Timeline from "../../Timeline"
import Details from "../../Details"
import "./index.css"
import { CloseOutlined } from "@ant-design/icons"

const { TabPane } = Tabs

const LensModal = ({ visible, onCancel, lens }) => {
  const [state] = useGlobalState()
  const scannerless = state.scanners.length === 0

  const modalTitle = (
    <div style={{ marginRight: "32px", marginBottom: "8px" }}>
      <LensTitle lens={lens} listView={true} />
    </div>
  )

  return (
    <Modal
      maskStyle={{ backdropFilter: "blur(8px)" }}
      footer={null}
      width="98vw"
      zIndex={100}
      visible={visible}
      onCancel={onCancel}
      bodyStyle={{
        padding: 0,
        overflow: "hidden",
        height: `${window.innerHeight * 0.98}px`,
        backgroundColor: "#252A2C",
      }}
      closeIcon={<CloseOutlined id="tour-step-8-trigger" />}
      destroyOnClose
      centered
    >
      <Tabs
        defaultActiveKey={scannerless ? "timeline" : "scans"}
        tabBarStyle={{
          padding: "24px 24px 0px 24px",
          marginBottom: "0px",
          backgroundColor: "#2A2F31",
        }}
        tabBarExtraContent={modalTitle}
        id="tour-step-3"
      >
        <TabPane tab="Details" key="details">
          <Details />
        </TabPane>
        <TabPane tab="Timeline" key="timeline">
          <Timeline />
        </TabPane>
        {!scannerless && (
          <TabPane tab="Scans" key="scans" className={css.scansTab}>
            <ScansTab />
          </TabPane>
        )}

        <TabPane tab="Scratches" key="scratches">
          <Scratches />
        </TabPane>
      </Tabs>
    </Modal>
  )
}

LensModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  lens: PropTypes.object,
}
export default LensModal
