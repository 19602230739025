import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import css from "./index.module.css"
import cx from "classnames"
import { notification, Breadcrumb, Divider, Modal } from "antd"
import StartScan from "./StartScan"
import SelectLens from "./SelectLens"
import AddLens from "./AddLens"
import Scanning from "./Scanning"
import UnknownLens from "./UnknownLens"
import FinalChecklist from "./FinalChecklist"
import SelectSerialNumber from "./SelectSerialNumber"
import { firestore as db } from "../../state/firebase"
import { MACHINE, STEPS, UNSUPPORTED_TEXT } from "../../state/constants"
import { useGlobalState } from "../../state"
import { HomeFilled } from "@ant-design/icons"
import Loading from "../common/Loading"
import { missingLensTypeFields, unsupported } from "../../helpers/lens"
import SandboxScan from "../Sandbox/SandboxScan"

export default function ScanWizard({ user, ...props }) {
  const [state, dispatch] = useGlobalState()
  const [, setUnsupportedModalVisible] = useState(false)

  const onStatusChange = (remoteScanner) => {
    if (
      remoteScanner.status === MACHINE.SCANNING &&
      remoteScanner.author_email !== undefined &&
      user.email !== remoteScanner.author_email
    ) {
      const { lens } = remoteScanner.user_scan_params
      notification["info"]({
        message: "Scan In Progress",
        description: `${remoteScanner.author_email} is currently scanning ${lens.make} ${lens.model} ${lens.focal_length}mm on ${remoteScanner.human_name}`,
        duration: 0,
        key: `${remoteScanner.id}-scan-in-progress`,
      })
    } else {
      notification.close(`${remoteScanner.id}-scan-in-progress`)
    }
  }

  useEffect(() => {
    const loaded =
      state.userOrgId !== undefined >= 1 &&
      state.scanners.length >= 1 &&
      user !== undefined
    loaded !== state.loaded && dispatch({ type: "SET_LOADED", loaded: loaded })
  }, [state.userOrgId, state.scanners, user])

  useEffect(() => {
    if (state.scanner === undefined || state.scanner === null) return

    db.onScannerStatusChange(state.userOrgId, state.scanner.id, onStatusChange)
  }, [state.scanner])

  const handleHomeClick = () => {
    dispatch({ type: "RESET_WIZARD" })
    setStep(STEPS.SELECT_SCANNER)
  }

  const handleContinue = (currentScanLens = null) => {
    const csl = currentScanLens || state.currentScanLens
    if (unsupported(csl)) {
      warning(csl)
      setUnsupportedModalVisible(true)
      return
    }

    // Ask user to add missing LensType information.
    if (missingLensTypeFields(csl)) {
      setStep(STEPS.ADD_LENS)
      return
    }

    // Ask user to X/O a lens that hasn't been scanned before.
    if (csl.calibrated_front_rear !== true) {
      setStep(STEPS.CALIBRATE_LENS)
      return
    }

    // Lens with serial number has been selected.
    if (csl.lens_id !== undefined) {
      setStep(STEPS.FINAL_CHECKLIST)
      return
    }

    // No missing LensType information.
    setStep(STEPS.SELECT_SERIAL_NUMBER)
  }

  // Modal to warn user that a lens is unsupported.
  const warning = (csl = state.currentScanLens) => {
    Modal.warning({
      title: "Lens Not Supported",
      centered: true,
      maskClosable: false,
      onOk: handleDismissModal,
      okText: "Select Another Lens",
      okButtonProps: {
        ghost: true,
      },
      content: (
        <div>
          <p>
            {`
            The ${csl.make}
            ${csl.model} 
            ${csl.focal_length_description}mm is not supported by our
            system at this time.
            `}
          </p>
          <p>{UNSUPPORTED_TEXT}</p>
          <p>Please try again with another lens.</p>
        </div>
      ),
    })
  }

  const handleDismissModal = () => {
    setUnsupportedModalVisible(false)
  }

  const getFocalLength = () => {
    // Avoid undefinedmm in breadcrumbs.
    if (state.currentScanLens.focal_length_description)
      return state.currentScanLens.focal_length_description
    if (state.currentScanLens.focal_length_mm)
      return state.currentScanLens.focal_length_mm
    if (state.scanner.user_scan_params.lens.focal_length)
      return state.scanner.user_scan_params.lens.focal_length
    if (state.currentScanLens.focal_length)
      return state.currentScanLens.focal_length
    return state.scanner.user_scan_params.lens.focal_length_mm
  }

  const setStep = (step) => {
    dispatch({ type: "SET_STEP", step: step })
  }

  const steps = [
    <StartScan key="StartScan" setStep={setStep} user={user} {...props} />,
    <SelectLens
      key="SelectLens"
      setStep={setStep}
      handleContinue={handleContinue}
      {...props}
    />,
    <AddLens
      key="AddLens"
      setStep={setStep}
      handleContinue={handleContinue}
      scanWizard={true}
      {...props}
    />,
    <Scanning key="Scanning" setStep={setStep} {...props} user={user} />,
    <UnknownLens key="UnknownLens" setStep={setStep} {...props} />,
    <FinalChecklist
      key="FinalChecklist"
      setStep={setStep}
      user={user}
      {...props}
    />,
    <SelectSerialNumber
      key="SelectSerialNumber"
      setStep={setStep}
      {...props}
    />,
    <SandboxScan key="SandboxScan" setStep={setStep} />,
  ]

  return (
    <div className={css.overviewContainer}>
      {state.loaded ? (
        <div className={cx("fadeIn", css.wizardContainer)}>
          <div className={css.wizard}>
            <Breadcrumb separator=">">
              {state.step > STEPS.SELECT_SCANNER && ( // Antd breadcrumb.items must be direct children of breadcrumbs hence the conditional repeat (neighboring JSX components must be wrapped in a div).
                <Breadcrumb.Item
                  onClick={handleHomeClick}
                  style={{ cursor: "pointer" }}
                >
                  <HomeFilled />
                </Breadcrumb.Item>
              )}
              {state.step > STEPS.SELECT_SCANNER && (
                <Breadcrumb.Item
                  onClick={() => setStep(STEPS.SELECT_LENS)}
                  style={{ cursor: "pointer" }}
                >
                  {state.scanner.human_name}
                </Breadcrumb.Item>
              )}
              {state.step === STEPS.ADD_LENS && (
                <Breadcrumb.Item style={{ cursor: "pointer" }}>
                  {state.currentScanLens ? "Lens Details" : "Adding Lens"}
                </Breadcrumb.Item>
              )}
              {state.step > STEPS.SELECT_LENS &&
                state.step !== STEPS.ADD_LENS &&
                state.currentScanLens && (
                  <Breadcrumb.Item style={{ cursor: "pointer" }}>
                    {`${state.currentScanLens.model} ${getFocalLength()}mm`}
                  </Breadcrumb.Item>
                )}
            </Breadcrumb>
            {state.step > STEPS.SELECT_SCANNER && (
              <Divider
                style={{
                  background: "#34383b",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
              ></Divider>
            )}
            <div className={css.wizardBody}>{steps[state.step]}</div>
          </div>
        </div>
      ) : (
        <Loading empty />
      )}
    </div>
  )
}

ScanWizard.propTypes = {
  user: PropTypes.object,
}
