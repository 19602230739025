import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import css from "./SerialNumberForm.module.css"
import { Form } from "antd"
import { Input, Button } from "antd"
import { DownOutlined, UpOutlined, BarcodeOutlined } from "@ant-design/icons"
import { useGlobalState } from "../../../state"

const SerialNumberForm = ({ addNewLens, handleContinue }) => {
  const [state, dispatch] = useGlobalState()
  const [addBarcode, setAddBarcode] = useState(false)
  const [serialNumber, setSerialNumber] = useState(null)
  const [barcode, setBarcode] = useState(null)
  let barcodeInput = useRef()

  const handleBarcodeToggle = () => {
    if (!addBarcode === true) setBarcode(null)
    setAddBarcode(!addBarcode)
  }

  useEffect(() => {
    if (addBarcode) barcodeInput.focus()
  }, [addBarcode])

  const handleSNChange = (e) => {
    setSerialNumber(e.target.value)
  }

  const handleBarcodeChange = (e) => {
    setBarcode(e.target.value)
  }

  const handleSubmit = () => {
    addNewLens(serialNumber, barcode).then((lens) => {
      // Scan Wizard next steps
      if (handleContinue !== undefined) {
        const csl = { ...state.currentScanLens, ...lens, lens_id: lens.id }
        dispatch({
          type: "SET_CURRENT_SCAN_LENS",
          lens: csl,
        })
        handleContinue(csl)
      } else {
        dispatch({
          type: "SET_CURRENT_SCAN_LENS",
          lens: null,
        })
      }
    })
  }

  return (
    <Form
      layout="inline"
      onFinish={handleSubmit}
      className={css.serialNumberForm}
    >
      <Form.Item>
        <Input onChange={handleSNChange} placeholder="Lens Serial Number" />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          className={css.bottomButton}
          htmlType="submit"
          disabled={serialNumber === "" || serialNumber === null}
        >
          Add Lens
        </Button>
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={handleBarcodeToggle} ghost>
          <BarcodeOutlined />
          {addBarcode ? <UpOutlined /> : <DownOutlined />}
        </Button>
      </Form.Item>

      {addBarcode && (
        <Form.Item>
          <Input
            placeholder="Lens Barcode"
            onChange={handleBarcodeChange}
            className={css.barcodeInput}
            ref={(input) => (barcodeInput = input)}
          />
        </Form.Item>
      )}
    </Form>
  )
}

SerialNumberForm.propTypes = {
  addNewLens: PropTypes.func,
  handleContinue: PropTypes.func,
}

export default SerialNumberForm
