import React, { useState } from "react"
import PropTypes from "prop-types"
import { Input as AntInput, Button, Upload, message, Checkbox } from "antd"
import {
  PictureOutlined,
  FileAddOutlined,
  EditOutlined,
} from "@ant-design/icons"
import cx from "classnames"
import css from "./index.module.css"
import HiddenTooltip from "../helpers/HiddenTooltip"

const { TextArea } = AntInput

const Input = ({
  onChange,
  addNote,
  value,
  onAddFile,
  file,
  image,
  editing,
  saveEditNote,
  cancelEditNote,
  setImage,
  setFile,
  id,
}) => {
  const [showOnPrintout, setShowOnPrintout] = useState(false)

  const handleButtonClick = () => {
    if (editing) {
      return saveEditNote(editing)
    }
    if (file) {
      onAddFile(file)
    }
    if (image) {
      onAddFile(image)
    }
    if (value) {
      setShowOnPrintout(false)
      addNote(showOnPrintout)
    }
  }

  const handleRemove = () => {
    setImage()
    setFile()
  }

  const beforeUpload = (file) => {
    const isLessThan2M = file.size / 1024 / 1024 < 2

    if (!isLessThan2M) {
      message.error("Image must be smaller than 2MB")
    }

    return isLessThan2M
  }

  return (
    <div className={cx(css.container, { [css.expand]: image || file })} id={id}>
      <TextArea
        onChange={onChange}
        className={css.textArea}
        rows={4}
        value={value}
      />
      <div className={css.buttons}>
        <div className={css.buttonGroup}>
          <div className={css.buttonContainer}>
            <Upload
              accept=".jpg, .jpeg, .png, .svg"
              transformFile={setImage}
              className={css.upload}
              beforeUpload={beforeUpload}
              fileList={
                image
                  ? [
                      {
                        uid: image.uid,
                        name: image.name,
                      },
                    ]
                  : []
              }
              onRemove={handleRemove}
            >
              <HiddenTooltip
                placement="bottom"
                title={<span>Images cannot be added to printout.</span>}
                hideIf={!showOnPrintout}
              >
                <Button
                  className={css.button}
                  disabled={editing || showOnPrintout}
                  shape="circle"
                  icon={<PictureOutlined />}
                />
              </HiddenTooltip>
            </Upload>
          </div>
          <div className={css.buttonContainer}>
            <Upload
              transformFile={setFile}
              className={css.upload}
              beforeUpload={beforeUpload}
              fileList={
                file
                  ? [
                      {
                        uid: file.uid,
                        name: file.name,
                      },
                    ]
                  : []
              }
              onRemove={handleRemove}
            >
              <HiddenTooltip
                placement="bottom"
                title={<span>Files cannot be added to printout</span>}
                hideIf={!showOnPrintout}
              >
                <Button
                  className={css.button}
                  disabled={editing || showOnPrintout}
                  shape="circle"
                  icon={<FileAddOutlined />}
                />
              </HiddenTooltip>
            </Upload>
          </div>
          <Button
            className={css.button}
            disabled={editing}
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
          />
        </div>
        {!editing && (
          <div className={css.checkboxContainer}>
            <Checkbox
              className={css.checkbox}
              onChange={(e) => setShowOnPrintout(e.target.checked)}
              checked={showOnPrintout}
              disabled={file || image}
            >
              <span className={css.checkboxText}>Show on Printout</span>
            </Checkbox>
          </div>
        )}

        <div>
          {editing && (
            <Button onClick={cancelEditNote} style={{ margin: "0 2px" }}>
              Cancel
            </Button>
          )}
          <Button onClick={handleButtonClick} style={{ margin: "0 2px" }}>
            {editing ? "Update" : file || image ? "Upload File" : "Add Note"}
          </Button>
        </div>
      </div>
    </div>
  )
}

Input.propTypes = {
  onChange: PropTypes.func,
  addNote: PropTypes.func,
  onAddFile: PropTypes.func,
  value: PropTypes.string,
  file: PropTypes.object,
  image: PropTypes.object,
  setImage: PropTypes.func,
  setFile: PropTypes.func,
  editing: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  saveEditNote: PropTypes.func,
  cancelEditNote: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.any, PropTypes.string]),
}

export default Input
