import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useParams } from "react-router-dom"
import { firestore as db } from "../../state/firebase"
import css from "./index.module.css"
import ScansList from "../common/ScansList"
import SurfaceSelect from "../common/SurfaceSelect"
import Loading from "../common/Loading"
import { PageHeader, Result } from "antd"
import tracisLogo from "../../images/tracis_horiz_white_sm.png"
import { FrownTwoTone } from "@ant-design/icons"
import generateSurfacesSet from "../../helpers/generateSurfacesSet"

const SharedLinkViewer = ({ guest }) => {
  const [leftScan, setLeftScan] = useState(undefined)
  const [rightScan, setRightScan] = useState(undefined)
  const [, setCompareScans] = useState(false)
  const [, setLensType] = useState(undefined)
  const [, setSerialNumber] = useState(undefined)
  const [, setSharingLink] = useState(undefined)
  const [surfaceSelect, setSurfaceSelect] = useState(undefined)
  const [surface, setSurface] = useState("")
  const [, setSurfaces] = useState([])
  const [title, setTitle] = useState(null)
  const [invalidId, setInvalidId] = useState(false)

  const { sharingLinkId } = useParams() // Get SharingLink ID from URL path.

  const handleSurfaceFilterChange = (eValue) => {
    const allSurfaces = generateSurfacesSet(leftScan, rightScan)
    setSurface(eValue)
    setSurfaceSelect(generateSurfaceSelect(eValue, allSurfaces))
  }

  // Antd Select component for switching surfaces.
  const generateSurfaceSelect = (oneSurface, allSurfaces) => {
    return (
      <SurfaceSelect
        surface={oneSurface}
        surfaces={allSurfaces}
        handleSurfaceFilterChange={handleSurfaceFilterChange}
      />
    )
  }

  // Construct make/model/focal_length/serial_number PageHeader title
  // (e.g. "ARRI Ultra Prime 50mm • 1101")
  const generateTitleString = (leftScan, lensType, serialNumber) => {
    if (
      leftScan === undefined ||
      lensType === undefined ||
      serialNumber === undefined
    ) {
      return ""
    }
    let str = []
    str.push(`${leftScan.lens_make || lensType.make}`)
    str.push(`${leftScan.lens_model || lensType.model}`)
    str.push(`${lensType.focal_length_description}mm`)
    str.push("•")
    str.push(`${serialNumber}`)
    return str.join(" ")
  }

  // Create a Set of all surface names present in leftScan and rightScan.
  useEffect(() => {
    if (leftScan === undefined) return
    const allSurfaces = generateSurfacesSet(leftScan, rightScan)
    // Persist surface upon scan change if it exists in new set of surfaces.
    if (allSurfaces.includes(surface)) {
      setSurfaceSelect(generateSurfaceSelect(surface, allSurfaces))
    } else {
      setSurface(allSurfaces[0])
      setSurfaceSelect(generateSurfaceSelect(allSurfaces[0], allSurfaces))
    }
    setSurfaces(allSurfaces)
  }, [leftScan, rightScan])

  // Fetch sharingLink if it exists, set variables for ScansList props.
  useEffect(() => {
    db.getSharingLink(sharingLinkId).then((data) => {
      if (data === undefined) {
        setInvalidId(true)
        return
      }
      setSerialNumber(data.serialNumber)
      setLeftScan(data.leftScan)
      setRightScan(data.rightScan)
      setSharingLink(data.sharingLink)
      setSurface(data.sharingLink.surface)
      setLensType(data.lensType)
      setCompareScans(leftScan && rightScan)
      const generatedTitle = generateTitleString(
        data.leftScan,
        data.lensType,
        data.serialNumber
      )
      setTitle(generatedTitle)
    })
  }, [sharingLinkId])

  // Tracis homepage-linked logo to display in GuestApp view.
  const avatar = guest && (
    <a
      className="tracisLogo"
      href="https://www.tracis.io"
      alt="Tracis company website"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={tracisLogo} alt="Tracis Logo" height={36} />
    </a>
  )

  return (
    <div>
      <div>
        <PageHeader
          title={avatar}
          subTitle={<div className={css.headerTitle}>{title}</div>}
          extra={[surfaceSelect]}
          className={css.header}
        />
        {invalidId ? ( // Display 404 page for invalid SharingLink ID.
          <Result
            className="centeredVH"
            icon={<FrownTwoTone twoToneColor="#A288FF" />}
            title="404"
            subTitle="Sorry, the given link has been revoked."
          />
        ) : leftScan && rightScan && surface ? ( // Display scans in compare mode.
          <ScansList
            surface={surface}
            leftScan={leftScan}
            rightScan={rightScan}
            compareScans
            guest={guest}
          />
        ) : leftScan ? ( // Display single scan.
          <ScansList
            surface={surface}
            leftScan={leftScan}
            compareScans={false}
            guest={guest}
          />
        ) : (
          <Loading title="Scans" />
        )}
      </div>
    </div>
  )
}

SharedLinkViewer.propTypes = {
  guest: PropTypes.bool,
}

export default SharedLinkViewer
